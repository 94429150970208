import React from 'react';
import SweetAlert from 'sweetalert2-react';

import './sweetAlert.css';

const CustomAlert = (props) => {
    return (
        <div ><SweetAlert style={{zIndex: "10000000"}}
                show={props.show}
                title={props.title}
                text={props.body}
                onConfirm={props.close}
        />
        </div>
    );
}

export default CustomAlert;