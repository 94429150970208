import React from 'react';

const Loader = () => {
    return <div>
        <div id="preloader">
            <div className="loader-container">
                <div className="progress-br float shadow">
                    <div className="progress__item"></div>
                </div>
            </div>
        </div>
    </div>
}

export default Loader;