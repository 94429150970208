import React from "react";
//import ReactDOM from 'react-dom';
import { render } from "react-snapshot";
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import reducer from "./store/reducer";

const rootReducer = combineReducers({ reducer: reducer });

const store = createStore(rootReducer);

//ReactDOM.render(
render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
