import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';

const PricingComponent = () => {
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        setShowLoader(false);
    }, []);

    return (
    showLoader? <Loader/> : <div className="adjust-top">
           <div id="pricing-box" className="section wb">
        <div className="container">
			<div className="row">
                <div className="col-md-4 col-sm-6">
                    <div className="pricingTable">
                        <div className="pricingTable-header">
                            <span className="heading">
                                <h3>Standard</h3>
                            </span>
                            <span className="price-value">$15<span className="month">month</span> <span>Lorem ipsum </span><span>Lorem ipsum dolor sit amet</span></span>
                        </div>

                        <div className="pricingContent">
                            <i className="fa fa-adjust"></i>
                            <ul>
                                <li>50GB Disk Space</li>
                                <li>50 Email Accounts</li>
                                <li>50GB Monthly Bandwidth</li>
                                <li>10 subdomains</li>
                                <li>50 Domains</li>
                            </ul>
                        </div>

                        <div className="pricingTable-sign-up">
                            <Link to="" className="hover-btn-new orange"><span>sign up</span></Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="pricingTable pink">
                        <div className="pricingTable-header">
                            <span className="heading">
                                <h3>Business</h3>
                            </span>
                            <span className="price-value">$30<span className="month">month</span> <span>Lorem ipsum</span><span>Lorem ipsum dolor sit amet</span></span>
                        </div>

                        <div className="pricingContent">
                            <i className="fa fa-briefcase"></i>
                            <ul>
                                <li>70GB Disk Space</li>
                                <li>70 Email Accounts</li>
                                <li>70GB Monthly Bandwidth</li>
                                <li>30 subdomains</li>
                                <li>70 Domains</li>
                            </ul>
                        </div>

                        <div className="pricingTable-sign-up">
                            <Link to="" className="hover-btn-new orange"><span>sign up</span></Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="pricingTable orange">
                        <div className="pricingTable-header">
                            <span className="heading">
                                <h3>Premium</h3>
                            </span>
                            <span className="price-value">$45<span className="month">month</span> <span>Lorem ipsum</span><span>Lorem ipsum dolor sit amet</span></span>
                        </div>

                        <div className="pricingContent">
                            <i className="fa fa-cube"></i>
                            <ul>
                                <li>80GB Disk Space</li>
                                <li>80 Email Accounts</li>
                                <li>80GB Monthly Bandwidth</li>
                                <li>40 subdomains</li>
                                <li>80 Domains</li>
                            </ul>
                        </div>

                        <div className="pricingTable-sign-up">
                            <Link to="" className="hover-btn-new orange"><span>sign up</span></Link>
                        </div>
                    </div>
                </div>
            </div>
		</div>
    </div>
    </div>)
}

export default PricingComponent;