import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
};

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: -1.2884,
          lng: 36.8233,
        }}
      ></Map>
    );
  }
}

export default GoogleApiWrapper({
  // apiKey: 'AIzaSyBmoaxv9v_LOijNPjmHxIG-Bls3O8jMR5w'
  apiKey: "AIzaSyBixgYQLhaiz3OMMtGShqJzpDNwBltb-AY",
})(MapContainer);
