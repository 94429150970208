import React, { useState } from "react";

const HistoryComponent = () => {
  const [translateValue, setTranslateValue] = useState({
    transform: "translate3d(0px, 0px, 0px)",
  });
  const [position, setPosition] = useState(0);

  const adjustTimeLine = (type) => {
    let currPosition = position;
    let nextPosition = 0;
    // if(type === "previous" && !(position >= -207)){
    //     if((-1955 <= (-window.innerWidth + position)) && ((position) >= -917)){
    //         setPosition(currPosition + 207.5);
    //     }
    // }
    // if(type === "next"){
    //     if(1900 >= (window.innerWidth + (position * -1))){
    //         setPosition(currPosition - 207.5)
    //     }
    // }

    if (
      2196 >= window.innerWidth + position * -1 &&
      0 <= window.innerWidth + position * -1
    ) {
      if (type === "previous") {
        nextPosition =
          window.innerWidth + (position + 207.5) * -1 >= window.innerWidth
            ? currPosition + 207.5
            : 0;
        setPosition(nextPosition);
      } else {
        nextPosition =
          window.innerWidth + (position - 207.5) * -1 <= 2000 - 415
            ? currPosition - 207.5
            : currPosition;
        setPosition(nextPosition);
      }
    }

    console.log(
      "previous position: " +
        currPosition +
        " Next Position: " +
        nextPosition +
        " client width: " +
        window.innerWidth
    );
  };

  return (
    <div>
      <section className="section lb">
        <div className="container">
          <div className="section-title row text-center">
            <div className="col-md-8 offset-md-2">
              <h3>Our history</h3>
              {/* <p className="lead">
                Lorem Ipsum dolroin gravida nibh vel velit auctor aliquet.
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem!
              </p> */}
            </div>
          </div>
          <div
            className="timeline timeline--loaded timeline--horizontal"
            style={{ opacity: "1" }}
          >
            <div className="timeline__wrap">
              <div
                className="timeline__items"
                style={{
                  width: "1660px",
                  height: "672px",
                  transform: "translate3d(" + position + "px, 0px, 0px)",
                }}
              >
                <div
                  className="timeline__item timeline__item--top"
                  style={{ width: "207.5px", height: "336px" }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-01">
                        <h2>2017</h2>
                        <p>
                          Started Civil Services Exam Mains coaching for GS I,
                          II, III subjects, Optional papers, and Current
                          Affairs. Coaching in Optional papers such as
                          Philosophy, Public Administration...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="timeline__item timeline__item--bottom"
                  style={{
                    width: "207.5px",
                    height: "336px",
                    transform: "translateY(336px)",
                  }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-02">
                        <h2>2014</h2>
                        <p>
                          Started Civil Services Exam coaching in Answer writing
                          skills, Essay paper Ethics (GS paper) and NCERT books.
                          Special batches were conducted for CSE – Prelims.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="timeline__item timeline__item--top"
                  style={{ width: "207.5px", height: "336px" }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-03">
                        <h2>2011</h2>
                        <p>
                          Started Civil Services Exam coaching for CSAT paper:
                          English Comprehension, Quantitative aptitude and
                          Reasoning. Indian Statistical Services, Combined
                          Defense Services. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="timeline__item timeline__item--bottom"
                  style={{
                    width: "207.5px",
                    height: "336px",
                    transform: "translateY(336px)",
                  }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-04">
                        <h2>2006</h2>
                        <p>
                          Added 10 additional courses to our list: SSC-CGL, SBI,
                          IBPS, APPSC, National Defense Academy, Indian Naval
                          Academy,Indian Foreign Services,Indian Economic
                          Services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="timeline__item timeline__item--top"
                  style={{ width: "207.5px", height: "336px" }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-01">
                        <h2>1992</h2>
                        <p>
                          Started English Grammar, Mathematics, History coaching
                          in Hyderabad. Special batches were started for
                          housewives as we believed in the adage, Teach a woman
                          – You teach a family.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="timeline__item timeline__item--bottom"
                  style={{
                    width: "207.5px",
                    height: "336px",
                    transform: "translateY(336px)",
                  }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-02">
                        <h2>1987</h2>
                        <p>
                          We have started our first batch in Nizamabad– English
                          grammar, Science, Mathematics for SSC students. We
                          trained about 500 students each year in various
                          skills.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="timeline__item timeline__item--top"
                  style={{ width: "207.5px", height: "336px" }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-03">
                        <h2>2004</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Integer dignissim neque condimentum lacus
                          dapibus. Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className="timeline__item timeline__item--bottom"
                  style={{
                    width: "207.5px",
                    height: "336px",
                    transform: "translateY(336px)",
                  }}
                >
                  <div className="timeline__item__inner">
                    <div className="timeline__content__wrap">
                      <div className="timeline__content img-bg-04">
                        <h2>2002</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Integer dignissim neque condimentum lacus
                          dapibus. Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <button
              className="timeline-nav-button timeline-nav-button--prev"
              style={{ top: "336px" }}
              disabled=""
              onClick={() => adjustTimeLine("previous")}
            >
              Previous
            </button>
            <button
              className="timeline-nav-button timeline-nav-button--next"
              style={{ top: "336px" }}
              onClick={() => adjustTimeLine("next")}
            >
              Next
            </button>
            <span className="timeline-divider" style={{ top: "336px" }}></span>
          </div>
        </div>
      </section>
      <div className="section cl">
        <div className="container">
          <div className="row text-left stat-wrap">
            <div className="col-md-4 col-sm-4 col-xs-12 auto-margin">
              <span
                data-scroll
                className="global-radius icon_wrap effect-1 alignleft"
              >
                <i className="fa fa-users" aria-hidden="true"></i>
              </span>
              <p className="stat_count">4000</p>
              <h3>Students</h3>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 auto-margin">
              <span
                data-scroll
                className="global-radius icon_wrap effect-1 alignleft"
              >
                <i className="fa fa-laptop" aria-hidden="true"></i>
              </span>
              <p className="stat_count">24</p>
              <h3>Courses</h3>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 auto-margin">
              <span
                data-scroll
                className="global-radius icon_wrap effect-1 alignleft"
              >
                <i className="fa fa-calendar" aria-hidden="true"></i>
              </span>
              <p className="stat_count">36</p>
              <h3>Years Completed</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryComponent;
