import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

const AboutDetailComponent = (props) => {
  useEffect(() => {
    // console.log("props", props);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="overviews" className="section wb">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="post-media wow fadeIn">
                <img
                  src="images/boss.png"
                  alt=""
                  className="img-fluid img-rounded"
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="message-box">
                <i
                  class="fa fa-quote-left margin-15px-bottom"
                  style={{ fontSize: "35px", color: "yellowgreen" }}
                ></i>

                <h2>Message From the Director's Desk</h2>
                <p>
                  GR IAS Academy was founded by Sri K. Gangadhara Rao who has
                  been successfully guiding hundreds and thousands of
                  job-aspirants across the state in securing prestigious
                  Government positions for the last 33 years. With the same
                  dedication, the entire study circle, as a unit, pledged in
                  crafting the knowledge of their students with no boundaries.
                  It is not an over statement to say that GR Study Circle is the
                  Best IAS academy in Hyderabad and can be one of the top
                  priorities to those who want to have Civils coaching in
                  Hyderabad.
                </p>
                <p
                  class="text-extra-dark-gray text-large display-block margin-30px-top alt-font font-weight-500"
                  style={{
                    marginBottom: "0px",
                    marginTop: "40px",
                    color: "black",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  Mr.K.Gangadhara Rao{" "}
                </p>
                <p class="display-block">From the Director's desk</p>
                {props.match.url === "/" ? (
                  <Link to="/about" className="hover-btn-new orange">
                    <span>Learn More</span>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="message-box">
                <h4>BEST IAS Coaching in Hyderabad</h4>
                <h2>Environment at G R IAS Academy</h2>
                <p>
                  With an amazing track record of our Director and experienced
                  faculty, G R IAS Academy always tries to provide the best
                  environment to its students so that they can put their total
                  concentration only on their studies. On the other hand, we
                  make sure that the faculty is available anytime the student
                  needs them. The students are given liberty to be in touch with
                  their faculty members and get their doubts cleared without any
                  hesitation. Apart from the class room lectures, we provide
                  online classes also to help out those candidates who cannot
                  attend the classes according to the prescribed schedule. Each
                  student will be provided their specific space so that they can
                  be able to follow the online classes anytime they want. More
                  details about the online lectures can be known by the students
                  when they directly come to the institute for admissions.
                </p>
                <p>
                  The most important aspect that keeps a student busy learning
                  is peaceful and calm environment around. To ensure the same,
                  the institute takes up only limited admissions that each
                  student has enough room to study with peace of mind. All the
                  aspiring students with an intention of having Civil services
                  coaching in Hyderabad can choose our institute in terms of
                  studying environment.
                </p>
                {props.match.url === "/" ? (
                  <Link to="/about" className="hover-btn-new orange">
                    <span>Learn More</span>
                  </Link>
                ) : null}
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="post-media wow fadeIn">
                <img
                  src="images/home1.jpg"
                  alt=""
                  className="img-fluid img-rounded"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="post-media wow fadeIn">
                <img
                  src="images/home2.jpg"
                  alt=""
                  className="img-fluid img-rounded"
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="message-box">
                <h2>Why Choose G R Study Circle?</h2>
                <p>
                  To explain in a precise manner of why to choose G R IAS
                  Academy over other institutes, we have listed out our
                  specialties right here so that aspirants can have a clear idea
                  of what we are.
                </p>
                <div>
                  <ul style={{ listStyle: "disc", color: "#888" }}>
                    <li>
                      Only limited strength per batch – to ensure peaceful
                      learning for the students.
                    </li>
                    <li>
                      Proper analysis on the learning and analyzing patterns of
                      each of the students and design their training
                      accordingly.
                    </li>
                    <li>
                      Encouraging faculty who takes special care on each of the
                      students and lead interactive sessions.
                    </li>
                    <li>
                      Online classes available to all the students at any given
                      time
                    </li>
                    <li>
                      Time to time analysis of the on-going syllabus and making
                      learning easy to the students.
                    </li>
                    <li>
                      Best quality teaching with much-experienced faculty from
                      the industry
                    </li>
                  </ul>
                </div>

                {props.match.url === "/" ? (
                  <Link to="/about" className="hover-btn-new orange">
                    <span>Learn More</span>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AboutDetailComponent);
