import React, { useEffect } from "react";
import { NavLink as Link } from "react-router-dom";

const HeaderComponent = (props) => {
  useEffect(() => console.log(props), []);

  return (
    <header className="top-navbar" id="nav-header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="images/logo8.png" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbars-host"
            aria-controls="navbars-rs-food"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div
            className="navbar-collapse collapse"
            id="navbars-host"
            onClick={(event) => {
              event.currentTarget.className = event.currentTarget.className.replace(
                "show",
                ""
              );
            }}
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  exact
                  to="/"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Home</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/course"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Courses</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/faculty"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Faculty</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/blog"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Blog</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  exact
                  to="/about"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">About</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  exact
                  to="/contact"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Contact</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  exact
                  // to="/pricing"
                  to="/paycheckout"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Pay Online</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  exact
                  // to="/pricing"
                  to="/test"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Test</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  exact
                  to="/register-onine"
                  activeClassName="activeStyling"
                >
                  <span className="nav-link-span">Apply</span>
                </Link>
              </li>
              {/* <li className="nav-item pr">
                <Link
                  to="#" exact
                  className="nav-link show-sign-up"
                  onClick={() => { props.showLogin("login") }}
                >
                  <span className="nav-link-span">Login</span>
                </Link>
                <div className="signup-drop-down">
                  <div id="tooltip" className="bottom">
                    <div className="tooltip-arrow" />
                    <div className="tooltip-label">New Customer? <Link to="/signup">
                      Signup
                      </Link></div>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderComponent;
