import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./RegisterOnline.css";
import Axios from "../../api";
import CustomAlert from "../sweet-alert/sweetAlert";

const RegisterOnlineComponent = () => {
  const [toggleStudentDetails, settoggleStudentDetails] = useState(true);
  const [
    toggleEducationalQualifications,
    setToggleEducationalQualifications,
  ] = useState(false);

  const [sscOjbect, setSSCObject] = useState({
    percentage: "",
    yop: "",
    institution: "",
    board: "",
  });
  const [interObject, setInterObject] = useState({
    percentage: "",
    yop: "",
    institution: "",
    board: "",
  });
  const [graduationObject, setGraduationObject] = useState({
    percentage: "",
    yop: "",
    institution: "",
    board: "",
  });
  const [pgOjbect, setPGObject] = useState({
    percentage: "",
    yop: "",
    institution: "",
    board: "",
  });
  const [hPGOjbect, sethPGCObject] = useState({
    percentage: "",
    yop: "",
    institution: "",
    board: "",
  });
  const [qualificationObject, setQualificationObject] = useState({
    percentage: "",
    yop: "",
    institution: "",
    board: "",
  });
  const [qualificationErrorObject, setQualificationErrorObject] = useState({
    percentageError: "",
    yopError: "",
    institutionError: "",
    boardError: "",
  });
  const [toggleQualification, setToggleQualification] = useState({
    name: "ssc",
  });

  const [studentDetailsObject, setStudentDetailsObject] = useState({
    name: "",
    dob: "",
    mail: "",
    mnumber: "",
    gender: "",
    medium: "",
    fatherName: "",
    fatherMobileNumber: "",
    fatherProfession: "",
    permanentAddress: "",
    coures: "",
    otherOptions: "",
    paidAmount: "",
    paidAmountTRNNumber: "",
    paidAmountDate: "",
  });

  const [studentDetailsErrorObject, setStudentDetailsErrorObject] = useState({
    nameError: null,
    dobError: null,
    mailError: null,
    mnumberError: null,
    genderError: null,
    mediumError: null,
    fatherNameError: null,
    fatherMobileNumberError: null,
    fatherProfessionError: null,
    permanentAddressError: null,
    couresError: null,
    otherOptionsError: null,
    paidAmountError: null,
    paidAmountTRNNumberError: null,
    paidAmountDateError: null,
  });

  const [additionalInfo, setAdditionalInfo] = useState({
    qualification: "",
    referrer: "",
    certify: "",
    tc: "",
    photo: false,
    signature: false,
  });

  const [additionalInfoError, setAdditionalInfoError] = useState({
    qualificationError: "",
    referrerError: "",
    certifyError: "",
    tcError: "",
  });

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const toggleQualificationDiv = (name) => {
    //saveData(name);
    setToggleQualification({ name: name });
    switch (name) {
      case "ssc":
        setQualificationObject({ ...sscOjbect });
        break;
      case "inter":
        setQualificationObject({ ...interObject });
        break;
      case "graduation":
        setQualificationObject({ ...graduationObject });
        break;
      case "pg":
        setQualificationObject({ ...pgOjbect });
        break;
      case "hpg":
        setQualificationObject({ ...hPGOjbect });
        break;
    }
  };

  const navigateOnQualificationDiv = (name) => {
    setToggleQualification({ name: name });
    switch (name) {
      case "ssc":
        setQualificationObject({ ...sscOjbect });
        break;
      case "inter":
        setQualificationObject({ ...interObject });
        break;
      case "graduation":
        setQualificationObject({ ...graduationObject });
        break;
      case "pg":
        setQualificationObject({ ...pgOjbect });
        break;
      case "hpg":
        setQualificationObject({ ...hPGOjbect });
        break;
    }
  };

  const saveData = (name) => {
    console.log(qualificationObject);
    const objecttoSave = { ...qualificationObject };
    setQualificationObject({
      percentage: "",
      yop: "",
      board: "",
      institution: "",
    });
    console.log("Saving previous tab to: ", name, objecttoSave);
    switch (name) {
      case "ssc": {
        setSSCObject({ ...objecttoSave });
        navigateOnQualificationDiv("inter");
        break;
      }
      case "inter": {
        setInterObject({ ...objecttoSave });
        navigateOnQualificationDiv("graduation");
        break;
      }
      case "graduation": {
        setGraduationObject({ ...objecttoSave });
        navigateOnQualificationDiv("pg");
        break;
      }
      case "pg": {
        setPGObject({ ...objecttoSave });
        navigateOnQualificationDiv("hpg");
        break;
      }
      case "hpg":
        sethPGCObject({ ...objecttoSave });
        break;
    }
  };

  const updateStudentNameHandler = (name) => {
    setStudentDetailsObject((prevState) => ({ ...prevState, name: name }));
    name === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          nameError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          nameError: false,
        }));
    return name === "";
  };

  const updateStudentDOBHandler = (dob) => {
    setStudentDetailsObject((prevState) => ({ ...prevState, dob: dob }));
    dob === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          dobError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          dobError: false,
        }));
    return dob === "";
  };

  const updateStudentMailHandler = (mail) => {
    setStudentDetailsObject((prevState) => ({ ...prevState, mail: mail }));
    (mail !== "" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) ||
    mail === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          mailError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          mailError: false,
        }));
    return (
      (mail !== "" &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) ||
      mail === ""
    );
  };

  const updateStudentMNumberHandler = (mNumber) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      mnumber: mNumber,
    }));
    (mNumber !== "" && !/([9876]{1})(\d{9})$/.test(mNumber)) || mNumber === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          mnumberError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          mnumberError: false,
        }));
    return (
      (mNumber !== "" && !/([9876]{1})(\d{9})$/.test(mNumber)) || mNumber === ""
    );
  };

  const updateStudentGenderHandler = (gender) => {
    setStudentDetailsObject((prevState) => ({ ...prevState, gender: gender }));
    gender === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          genderError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          genderError: false,
        }));
    return gender === "";
  };

  const updateStudentMediumHandler = (medium) => {
    setStudentDetailsObject((prevState) => ({ ...prevState, medium: medium }));
    medium === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          mediumError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          mediumError: false,
        }));
    return medium === "";
  };

  const updateStudentFatherNameHandler = (fatherName) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      fatherName: fatherName,
    }));
    fatherName === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          fatherNameError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          fatherNameError: false,
        }));
    return fatherName === "";
  };

  const updateStudentFatherMobileNumberHandler = (fMobNumber) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      fatherMobileNumber: fMobNumber,
    }));
    (fMobNumber !== "" && !/([9876]{1})(\d{9})$/.test(fMobNumber)) ||
    fMobNumber === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          fatherMobileNumberError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          fatherMobileNumberError: false,
        }));
    return (
      (fMobNumber !== "" && !/([9876]{1})(\d{9})$/.test(fMobNumber)) ||
      fMobNumber === ""
    );
  };

  const updateStudentFatherProfessionHandler = (fatherProfession) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      fatherProfession: fatherProfession,
    }));
    fatherProfession === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          fatherProfessionError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          fatherProfessionError: false,
        }));
    return fatherProfession === "";
  };

  const updateStudentPermanentAddressHandler = (pAddress) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      permanentAddress: pAddress,
    }));
    pAddress === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          permanentAddressError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          permanentAddressError: false,
        }));
    return pAddress === "";
  };

  const updateStudentCourseHandler = (course) => {
    setStudentDetailsObject((prevState) => ({ ...prevState, coures: course }));
    course === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          couresError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          couresError: false,
        }));
    return course === "";
  };

  const updateStudentOtherOptionHandler = (oOption) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      otherOptions: oOption,
    }));
    oOption === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          otherOptionsError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          otherOptionsError: false,
        }));
    return oOption === "";
  };

  const updateStudentAmountPaidHandler = (amountPaid) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      paidAmount: amountPaid,
    }));
    amountPaid === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          paidAmountError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          paidAmountError: false,
        }));
    return amountPaid === "";
  };

  const updateStudentPaidAmountTRNNumberHanler = (paTRNNumber) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      paidAmountTRNNumber: paTRNNumber,
    }));
    paTRNNumber === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          paidAmountTRNNumberError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          paidAmountTRNNumberError: false,
        }));
    return paTRNNumber === "";
  };

  const updateStudentPaidAmountDateHandler = (paidAmountDate) => {
    setStudentDetailsObject((prevState) => ({
      ...prevState,
      paidAmountDate: paidAmountDate,
    }));
    paidAmountDate === ""
      ? setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          paidAmountDateError: true,
        }))
      : setStudentDetailsErrorObject((prevState) => ({
          ...prevState,
          paidAmountDateError: false,
        }));
    return paidAmountDate === "";
  };

  const updateQualificationPercentate = (per) => {
    setQualificationObject((prevState) => ({ ...prevState, percentage: per }));
    per === ""
      ? setQualificationErrorObject((prevState) => ({
          ...prevState,
          percentageError: true,
        }))
      : setQualificationErrorObject((prevState) => ({
          ...prevState,
          percentageError: false,
        }));
    return per === "";
  };

  const updateQualificationYOP = (yop) => {
    setQualificationObject((prevState) => ({ ...prevState, yop: yop }));
    yop === ""
      ? setQualificationErrorObject((prevState) => ({
          ...prevState,
          yopError: true,
        }))
      : setQualificationErrorObject((prevState) => ({
          ...prevState,
          yopError: false,
        }));
    return yop === "";
  };

  const updateQualificationInstitution = (inst) => {
    setQualificationObject((prevState) => ({
      ...prevState,
      institution: inst,
    }));
    inst === ""
      ? setQualificationErrorObject((prevState) => ({
          ...prevState,
          institutionError: true,
        }))
      : setQualificationErrorObject((prevState) => ({
          ...prevState,
          institutionError: false,
        }));
    return inst === "";
  };

  const updateQualificationBoard = (board) => {
    setQualificationObject((prevState) => ({ ...prevState, board: board }));
    board === ""
      ? setQualificationErrorObject((prevState) => ({
          ...prevState,
          boardError: true,
        }))
      : setQualificationErrorObject((prevState) => ({
          ...prevState,
          boardError: false,
        }));
    return board === "";
  };

  const updateAdditionalInfoQualification = (qualification) => {
    setAdditionalInfo((prevState) => ({
      ...prevState,
      qualification: qualification,
    }));
    qualification === ""
      ? setAdditionalInfoError((prevState) => ({
          ...prevState,
          qualificationError: true,
        }))
      : setAdditionalInfoError((prevState) => ({
          ...prevState,
          qualificationError: false,
        }));
    return qualification === "";
  };

  const updateAdditionalInfoReferrer = (ref) => {
    setAdditionalInfo((prevState) => ({ ...prevState, referrer: ref }));
    ref === ""
      ? setAdditionalInfoError((prevState) => ({
          ...prevState,
          referrerError: true,
        }))
      : setAdditionalInfoError((prevState) => ({
          ...prevState,
          referrerError: false,
        }));
    return ref === "";
  };

  const updateAdditionalInfoCertfiy = (isChecked) => {
    setAdditionalInfo((prevState) => ({ ...prevState, certify: isChecked }));
    setAdditionalInfoError((prevState) => ({
      ...prevState,
      certifyError: !isChecked,
    }));
    return !isChecked;
  };

  const updateAdditionalInfoTC = (isChecked) => {
    setAdditionalInfo((prevState) => ({ ...prevState, tc: isChecked }));
    setAdditionalInfoError((prevState) => ({
      ...prevState,
      tcError: !isChecked,
    }));
    return !isChecked;
  };

  const updateAdditionalInfoPhoto = (event) => {
    //event.persist();
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    fetch("https://cdn.griasacademy.com/upload", {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((d) =>
        setAdditionalInfo((prevState) => ({
          ...prevState,
          photo: "/file/" + d.path,
        }))
      );
  };

  const updateAdditionalInfoSignature = (event) => {
    //event.persist();
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    console.log(event.target.files[0]);
    fetch("https://cdn.griasacademy.com/upload", {
      method: "POST",
      body: formData,
    })
      .then((r) => r.json())
      .then((d) =>
        setAdditionalInfo((prevState) => ({
          ...prevState,
          signature: "/file/" + d.path,
        }))
      );
    console.log(formData);
  };

  const validateObject = () => {
    console.log(
      (!updateStudentNameHandler(studentDetailsObject.name) +
        " " +
        !updateStudentDOBHandler(studentDetailsObject.dob) +
        " " +
        !updateStudentMailHandler(studentDetailsObject.mail) +
        " " +
        !updateStudentMNumberHandler(studentDetailsObject.mnumber) +
        " " +
        !updateStudentGenderHandler(studentDetailsObject.gender) +
        " " +
        !updateStudentMediumHandler(studentDetailsObject.medium) +
        " " +
        !updateStudentFatherNameHandler(studentDetailsObject.fatherName) +
        " " +
        !updateStudentFatherMobileNumberHandler(
          studentDetailsObject.fatherMobileNumber
        ) +
        " " +
        !updateStudentFatherProfessionHandler(
          studentDetailsObject.fatherProfession
        ) +
        " " +
        !updateStudentPermanentAddressHandler(
          studentDetailsObject.permanentAddress
        ) +
        " " +
        !updateStudentCourseHandler(studentDetailsObject.coures) +
        " " +
        !updateStudentOtherOptionHandler(studentDetailsObject.otherOptions) +
        " " +
        !updateStudentAmountPaidHandler(studentDetailsObject.paidAmount) +
        " " +
        !updateStudentPaidAmountTRNNumberHanler(
          studentDetailsObject.paidAmountTRNNumber
        ) +
        " " +
        !updateStudentPaidAmountDateHandler(
          studentDetailsObject.paidAmountDate
        ) +
        " " +
        !updateQualificationPercentate(sscOjbect.percentage) +
        " " +
        !updateQualificationYOP(sscOjbect.yop) +
        " " +
        !updateQualificationInstitution(sscOjbect.institution) +
        " " +
        !updateQualificationBoard(sscOjbect.board) +
        " " +
        !updateQualificationPercentate(interObject.percentage) +
        " " +
        !updateQualificationYOP(interObject.yop) +
        " " +
        !updateQualificationInstitution(interObject.institution) +
        " " +
        !updateQualificationBoard(interObject.board) +
        " " +
        !updateQualificationPercentate(graduationObject.percentage) +
        " " +
        !updateQualificationYOP(graduationObject.yop) +
        " " +
        !updateQualificationInstitution(graduationObject.institution) +
        " " +
        !updateQualificationBoard(graduationObject.board) +
        " " +
        !updateAdditionalInfoQualification(additionalInfo.qualification) +
        " " +
        !updateAdditionalInfoReferrer(additionalInfo.referrer) +
        " " +
        !updateAdditionalInfoCertfiy(additionalInfo.certify) +
        " " +
        !updateAdditionalInfoTC(additionalInfo.tc) +
        " " +
        additionalInfo.photo !==
        "" + " " + additionalInfo.signature) !==
        ""
    );
    return (
      !updateStudentNameHandler(studentDetailsObject.name) &&
      !updateStudentDOBHandler(studentDetailsObject.dob) &&
      !updateStudentMailHandler(studentDetailsObject.mail) &&
      !updateStudentMNumberHandler(studentDetailsObject.mnumber) &&
      !updateStudentGenderHandler(studentDetailsObject.gender) &&
      !updateStudentMediumHandler(studentDetailsObject.medium) &&
      !updateStudentFatherNameHandler(studentDetailsObject.fatherName) &&
      !updateStudentFatherMobileNumberHandler(
        studentDetailsObject.fatherMobileNumber
      ) &&
      !updateStudentFatherProfessionHandler(
        studentDetailsObject.fatherProfession
      ) &&
      !updateStudentPermanentAddressHandler(
        studentDetailsObject.permanentAddress
      ) &&
      !updateStudentCourseHandler(studentDetailsObject.coures) &&
      !updateStudentOtherOptionHandler(studentDetailsObject.otherOptions) &&
      !updateStudentAmountPaidHandler(studentDetailsObject.paidAmount) &&
      !updateStudentPaidAmountTRNNumberHanler(
        studentDetailsObject.paidAmountTRNNumber
      ) &&
      !updateStudentPaidAmountDateHandler(
        studentDetailsObject.paidAmountDate
      ) &&
      !updateQualificationPercentate(sscOjbect.percentage) &&
      !updateQualificationYOP(sscOjbect.yop) &&
      !updateQualificationInstitution(sscOjbect.institution) &&
      !updateQualificationBoard(sscOjbect.board) &&
      !updateQualificationPercentate(interObject.percentage) &&
      !updateQualificationYOP(interObject.yop) &&
      !updateQualificationInstitution(interObject.institution) &&
      !updateQualificationBoard(interObject.board) &&
      !updateQualificationPercentate(graduationObject.percentage) &&
      !updateQualificationYOP(graduationObject.yop) &&
      !updateQualificationInstitution(graduationObject.institution) &&
      !updateQualificationBoard(graduationObject.board) &&
      !updateAdditionalInfoQualification(additionalInfo.qualification) &&
      !updateAdditionalInfoReferrer(additionalInfo.referrer) &&
      !updateAdditionalInfoCertfiy(additionalInfo.certify) &&
      !updateAdditionalInfoTC(additionalInfo.tc) &&
      additionalInfo.photo !== "" &&
      additionalInfo.signature !== ""
    );
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    console.log("is Formdata Valid: " + validateObject());
    if (validateObject()) {
      const formObject = {
        form: {
          studentName: studentDetailsObject.name,
          dob: studentDetailsObject.dob,
          email: studentDetailsObject.email,
          contactNumber: studentDetailsObject.mnumber,
          gender: studentDetailsObject.gender,
          mediumOfClass: studentDetailsObject.medium,
          fatherName: studentDetailsObject.fatherName,
          fatherMobileNumber: studentDetailsObject.fatherMobileNumber,
          fatherProfession: studentDetailsObject.fatherProfession,
          permanentAddress: studentDetailsObject.permanentAddress,
          likedCourse: studentDetailsObject.coures,
          optionals: studentDetailsObject.otherOptions,
          paidAmount: Number(studentDetailsObject.paidAmount),
          trnNumber: studentDetailsObject.paidAmountTRNNumber,
          amountPaidDate: studentDetailsObject.paidAmountDate,
          employeeHolder: additionalInfo.qualification,
          knownAboutGRIAS: additionalInfo.referrer,
          photo: additionalInfo.photo,
          signature: additionalInfo.signature,
        },
        eduData: [
          {
            nameOfTheExam: "SSC",
            cgpaOrMarks: sscOjbect.percentage,
            yearOfPassing: sscOjbect.yop,
            nameOfTheInstitute: sscOjbect.institution,
            nameOfTheUniversity: sscOjbect.board,
          },
          {
            nameOfTheExam: "Inter",
            cgpaOrMarks: interObject.percentage,
            yearOfPassing: interObject.yop,
            nameOfTheInstitute: interObject.institution,
            nameOfTheUniversity: interObject.board,
          },
          {
            nameOfTheExam: "Graduation",
            cgpaOrMarks: graduationObject.percentage,
            yearOfPassing: graduationObject.yop,
            nameOfTheInstitute: graduationObject.institution,
            nameOfTheUniversity: graduationObject.board,
          },
        ],
      };
      if (!isObjectEmpy(pgOjbect)) {
        formObject.eduData.push({
          nameOfTheExam: "PG",
          cgpaOrMarks: pgOjbect.percentage,
          yearOfPassing: pgOjbect.yop,
          nameOfTheInstitute: pgOjbect.institution,
          nameOfTheUniversity: pgOjbect.board,
        });
      }
      if (!isObjectEmpy(hPGOjbect)) {
        formObject.eduData.push({
          nameOfTheExam: "HPG",
          cgpaOrMarks: hPGOjbect.percentage,
          yearOfPassing: hPGOjbect.yop,
          nameOfTheInstitute: hPGOjbect.institution,
          nameOfTheUniversity: hPGOjbect.board,
        });
      }
      console.log("post Object: ", formObject);
      Axios.post("v1/forms/form", formObject)
        .then((res) => {
          setSweetAlert({
            show: true,
            title: "Success",
            body: "Registered successfully online.",
          });
        })
        .catch((err) => {
          setSweetAlert({
            show: true,
            title: "Failed!",
            body: "Registrtion failed, plesae try again later.",
          });
        });
    }
    return false;
  };

  const isObjectEmpy = (obj) => {
    let isKeysEmpty = [];
    for (let key in obj) {
      isKeysEmpty.push(obj[key] === "");
    }
    console.log(isKeysEmpty.toString());
    return isKeysEmpty.toString().indexOf("true") >= 0;
  };

  return (
    <div
      style={{ marginTop: "70px" }}
      className="register-online-form-div-wrapper"
    >
      <div>
        <div className="center py-3"></div>
        <div className="p-2 mx-3 container">
          <form onSubmit={(event) => onFormSubmit(event)}>
            <div className="student-details-wrapper mb-3 pb-3">
              <div
                className="ribbon show py-3 px-3"
                onClick={() => settoggleStudentDetails(!toggleStudentDetails)}
              >
                <p>Student Detials</p>
                <span className="float-right">
                  <i className="arrow down"></i>
                </span>
              </div>
              <div
                className="students-details-form-holder px-3"
                style={{ display: toggleStudentDetails ? "block" : "none" }}
              >
                <div className="row my-3">
                  <div className="col col-lg-4 col-12">
                    <label>Name of the Student</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.nameError &&
                        studentDetailsObject.name !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.nameError &&
                            studentDetailsObject.name !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.name}
                      onChange={(event) =>
                        updateStudentNameHandler(event.target.value)
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>Date of Birth</label>
                    <input
                      type="date"
                      className={
                        studentDetailsErrorObject.dobError &&
                        studentDetailsObject.dob !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.dobError &&
                            studentDetailsObject.dob !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.dob}
                      onChange={(event) =>
                        updateStudentDOBHandler(event.target.value)
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>E-mail id</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.mailError &&
                        studentDetailsObject.mail !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.mailError &&
                            studentDetailsObject.mail !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.mail}
                      onChange={(event) =>
                        updateStudentMailHandler(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col col-lg-4 col-12">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.mnumberError &&
                        studentDetailsObject.mnumber !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.mnumberError &&
                            studentDetailsObject.mnumber !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.mnumber}
                      onChange={(event) =>
                        updateStudentMNumberHandler(event.target.value)
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>Gender</label>
                    <select
                      className={
                        studentDetailsErrorObject.genderError &&
                        studentDetailsObject.gender !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.genderError &&
                            studentDetailsObject.gender !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.gender}
                      onChange={(event) =>
                        updateStudentGenderHandler(event.target.value)
                      }
                    >
                      <option value="" default hidden selected>
                        Select
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>Medium</label>
                    <select
                      className={
                        studentDetailsErrorObject.mediumError &&
                        studentDetailsObject.medium !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.mediumError &&
                            studentDetailsObject.medium !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.medium}
                      onChange={(event) =>
                        updateStudentMediumHandler(event.target.value)
                      }
                    >
                      <option value="" default hidden selected>
                        Select
                      </option>
                      <option value="telugu">Telugu</option>
                      <option value="english">English</option>
                    </select>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col col-lg-4 col-12">
                    <label>Father Name</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.fatherNameError &&
                        studentDetailsObject.fatherName !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.fatherNameError &&
                            studentDetailsObject.fatherName !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.fatherName}
                      onChange={(event) =>
                        updateStudentFatherNameHandler(event.target.value)
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      className={
                        studentDetailsErrorObject.fatherMobileNumberError &&
                        studentDetailsObject.fatherMobileNumber !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.fatherMobileNumberError &&
                            studentDetailsObject.fatherMobileNumber !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.fatherMobileNumber}
                      onChange={(event) =>
                        updateStudentFatherMobileNumberHandler(
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-sm-12">
                    <label>Father Profession</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.fatherProfessionError &&
                        studentDetailsObject.fatherProfession !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.fatherProfessionError &&
                            studentDetailsObject.fatherProfession !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.fatherProfession}
                      onChange={(event) =>
                        updateStudentFatherProfessionHandler(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col col-md-12">
                    <label>Permanent Address</label>
                    <textarea
                      type="text"
                      className={
                        studentDetailsErrorObject.permanentAddressError &&
                        studentDetailsObject.permanentAddress !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.permanentAddressError &&
                            studentDetailsObject.permanentAddress !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      onChange={(event) =>
                        updateStudentPermanentAddressHandler(event.target.value)
                      }
                    >
                      {studentDetailsObject.permanentAddress}
                    </textarea>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col col-lg-6 col-sm-12">
                    <label>Course you would like to join:</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.couresError &&
                        studentDetailsObject.coures !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.couresError &&
                            studentDetailsObject.coures !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.coures}
                      onChange={(event) =>
                        updateStudentCourseHandler(event.target.value)
                      }
                    />
                  </div>
                  <div className="col col-lg-6 col-sm-12">
                    <label>Optional:</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.otherOptionsError &&
                        studentDetailsObject.otherOptions !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.otherOptionsError &&
                            studentDetailsObject.otherOptions !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.otherOptions}
                      onChange={(event) =>
                        updateStudentOtherOptionHandler(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col col-lg-4 col-12">
                    <label>Amount Paid:</label>
                    <input
                      type="number"
                      className={
                        studentDetailsErrorObject.paidAmountError &&
                        studentDetailsObject.paidAmount !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.paidAmountError &&
                            studentDetailsObject.paidAmount !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.paidAmount}
                      onChange={(event) =>
                        updateStudentAmountPaidHandler(event.target.value)
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>TRN Number:</label>
                    <input
                      type="text"
                      className={
                        studentDetailsErrorObject.paidAmountTRNNumberError &&
                        studentDetailsObject.paidAmountTRNNumber !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.paidAmountTRNNumberError &&
                            studentDetailsObject.paidAmountTRNNumber !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.paidAmountTRNNumber}
                      onChange={(event) =>
                        updateStudentPaidAmountTRNNumberHanler(
                          event.target.value
                        )
                      }
                    />
                  </div>
                  <div className="col col-lg-4 col-12">
                    <label>Date:</label>
                    <input
                      type="date"
                      className={
                        studentDetailsErrorObject.paidAmountDateError &&
                        studentDetailsObject.paidAmountDate !== ""
                          ? "inputFieldError form-control"
                          : !studentDetailsErrorObject.paidAmountDateError &&
                            studentDetailsObject.paidAmountDate !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={studentDetailsObject.paidAmountDate}
                      onChange={(event) =>
                        updateStudentPaidAmountDateHandler(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="educational-qualificaitons pb-3">
              <div
                className="ribbon show py-3 px-3"
                onClick={() =>
                  setToggleEducationalQualifications(
                    !toggleEducationalQualifications
                  )
                }
              >
                <p>Educational Qualifications</p>
                <span className="float-right">
                  <i className="arrow down"></i>
                </span>
              </div>
              <div
                className="educational-qualifications px-3"
                style={{
                  display: toggleEducationalQualifications ? "block" : "none",
                }}
              >
                <div className="row my-3 px-3 text-center fl-di">
                  <div className="form-nav-wrapper d-flex">
                    <div className="form-nav-wrapper-one">
                      <div className="table-title my-2 px-2 text-center b-r">
                        <Link
                          to="#"
                          className={
                            toggleQualification.name === "ssc"
                              ? "active-class-name"
                              : ""
                          }
                          onClick={() => toggleQualificationDiv("ssc")}
                        >
                          Class X
                        </Link>
                      </div>
                      <div className="table-title my-2 px-2 text-center b-r">
                        <Link
                          to="#"
                          className={
                            toggleQualification.name === "inter"
                              ? "active-class-name"
                              : ""
                          }
                          onClick={() => toggleQualificationDiv("inter")}
                        >
                          Class XII
                        </Link>
                      </div>
                      <div className="table-title my-2 px-2 text-center b-r">
                        <Link
                          to="#"
                          className={
                            toggleQualification.name === "graduation"
                              ? "active-class-name"
                              : ""
                          }
                          onClick={() => toggleQualificationDiv("graduation")}
                        >
                          Graduation
                        </Link>
                      </div>
                    </div>
                    <div className="form-nav-wrapper-one">
                      <div className="table-title my-2 px-2 text-center b-r">
                        <Link
                          to="#"
                          className={
                            toggleQualification.name === "pg"
                              ? "active-class-name"
                              : ""
                          }
                          onClick={() => toggleQualificationDiv("pg")}
                        >
                          Post Graduation
                        </Link>
                      </div>
                      <div className="table-title my-2 px-2 text-center">
                        <Link
                          to="#"
                          className={
                            toggleQualification.name === "hpg"
                              ? "active-class-name"
                              : ""
                          }
                          onClick={() => toggleQualificationDiv("hpg")}
                        >
                          Any Other Qualifications
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-table">
                    <div className="table-body">
                      <div className="table-tr row my-2">
                        <div className="col-md-6 text-right">
                          <span>% of Marks/CGPA</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className={
                              qualificationErrorObject.percentageError &&
                              qualificationObject.percentage !== ""
                                ? "inputFieldError form-control"
                                : !qualificationErrorObject.percentageError &&
                                  qualificationObject.percentage !== ""
                                ? "inputFieldSuccess form-control"
                                : "form-control"
                            }
                            value={qualificationObject.percentage}
                            onChange={(event) =>
                              updateQualificationPercentate(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="table-tr row my-2">
                        <div className="col-md-6 text-right">
                          <span>Year of passing</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            className={
                              qualificationErrorObject.yopError &&
                              qualificationObject.yop !== ""
                                ? "inputFieldError form-control"
                                : !qualificationErrorObject.yopError &&
                                  qualificationObject.yop !== ""
                                ? "inputFieldSuccess form-control"
                                : "form-control"
                            }
                            value={qualificationObject.yop}
                            onChange={(event) =>
                              updateQualificationYOP(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="table-tr row my-2">
                        <div className="col-md-6 text-right">
                          <span>Name of the institution</span>
                        </div>
                        <div className="col-md-6 text-right">
                          <input
                            type="text"
                            className={
                              qualificationErrorObject.institutionError &&
                              qualificationObject.institution !== ""
                                ? "inputFieldError form-control"
                                : !qualificationErrorObject.institutionError &&
                                  qualificationObject.institution !== ""
                                ? "inputFieldSuccess form-control"
                                : "form-control"
                            }
                            value={qualificationObject.institution}
                            onChange={(event) =>
                              updateQualificationInstitution(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="table-tr row my-2">
                        <div className="col-md-6 text-right">
                          <span>Name of the Board/University</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className={
                              qualificationErrorObject.boardError &&
                              qualificationObject.board !== ""
                                ? "inputFieldError form-control"
                                : !qualificationErrorObject.boardError &&
                                  qualificationObject.board !== ""
                                ? "inputFieldSuccess form-control"
                                : "form-control"
                            }
                            value={qualificationObject.institution}
                            value={qualificationObject.board}
                            onChange={(event) =>
                              updateQualificationBoard(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <input
                      type="button"
                      value="Save &amp; Next"
                      className="btn btn-success"
                      onClick={() => saveData(toggleQualification.name)}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col col-12">
                    <span>Employeed / Unemployeed</span>
                    <select
                      className={
                        additionalInfoError.qualificationError &&
                        additionalInfo.qualification !== ""
                          ? "inputFieldError form-control"
                          : !additionalInfoError.qualificationError &&
                            additionalInfo.qualification !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={additionalInfo.qualification}
                      onChange={(event) =>
                        updateAdditionalInfoQualification(event.target.value)
                      }
                    >
                      <option default hidden selected>
                        Select
                      </option>
                      <option value="employeed">Employeed</option>
                      <option value="unemployeed">Unemployeed</option>
                    </select>
                  </div>
                  <div className="col col-12">
                    <span>How did you know about GR IAS STUDY CIRCLE:</span>
                    <input
                      type="text"
                      className={
                        additionalInfoError.referrerError &&
                        additionalInfo.referrer !== ""
                          ? "inputFieldError form-control"
                          : !additionalInfoError.referrerError &&
                            additionalInfo.referrer !== ""
                          ? "inputFieldSuccess form-control"
                          : "form-control"
                      }
                      value={additionalInfo.referrer}
                      onChange={(event) =>
                        updateAdditionalInfoReferrer(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col col-12">
                <label>
                  <input
                    type="checkbox"
                    className={
                      additionalInfoError.certifyError &&
                      additionalInfo.certify !== ""
                        ? "inputFieldError"
                        : !additionalInfoError.certifyError &&
                          additionalInfo.certify !== ""
                        ? "inputFieldSuccess"
                        : ""
                    }
                    selected={additionalInfo.certify}
                    onChange={(event) =>
                      updateAdditionalInfoCertfiy(event.target.checked)
                    }
                  />
                  <span className="ml-2">
                    I certify that the particulars/information given by me in
                    this form are correct to the best of my knowledge.
                  </span>
                </label>
              </div>
              <div className="col col-12">
                <label>
                  <input
                    type="checkbox"
                    className={
                      additionalInfoError.tcError && additionalInfo.tc !== ""
                        ? "inputFieldError"
                        : !additionalInfoError.tcError &&
                          additionalInfo.tc !== ""
                        ? "inputFieldSuccess"
                        : ""
                    }
                    selected={additionalInfo.tc}
                    onChange={(event) =>
                      updateAdditionalInfoTC(event.target.checked)
                    }
                  />
                  <span className="ml-2">
                    I hereby undertake to abide by all the policies, rules &amp;
                    regulations of GR IAS ACADEMY.
                  </span>
                </label>
              </div>
            </div>
            <div className="row my-3">
              <div className="col col-12">
                <label>
                  <span>Upload Photo</span>
                  <input
                    type="file"
                    accept="image/*"
                    name="file"
                    className="form-control nb"
                    onChange={(event) => updateAdditionalInfoPhoto(event)}
                  />
                </label>
              </div>
              <div className="col col-12">
                <label>
                  <span>Upload Signature</span>
                  <input
                    type="file"
                    accept="image/*"
                    name="file2"
                    className="form-control nb"
                    onChange={(event) => updateAdditionalInfoSignature(event)}
                  />
                </label>
              </div>
            </div>
            <div
              className="row my-3"
              style={{ display: "flow-root", textAlign: "right" }}
            >
              <input
                type="submit"
                value="Submit"
                className="btn btn-success mr-3"
              />
            </div>
          </form>
        </div>
      </div>
      <CustomAlert
        show={sweetAlert.show}
        title={sweetAlert.title}
        body={sweetAlert.body}
        close={() =>
          setSweetAlert((prevState) => ({ ...prevState, show: false }))
        }
      />
    </div>
  );
};

export default RegisterOnlineComponent;
