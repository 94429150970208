import React from "react";
import { Link, withRouter } from "react-router-dom";

const FooterComponent = (props) => {
  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-xs-12">
              <div className="widget clearfix">
                <div className="widget-title">
                  <h3>About US</h3>
                </div>
                <p>
                  {" "}
                  Hyderabad based GR study circle/IAS academy has been started
                  with the purpose of training aspirants for the Civil Services
                  Examination. Sri K. Gangadhara Rao has a long and rich
                  experience of guiding aspirants for various examinations,
                  conducted by UPSC.
                </p>
                <div className="footer-right" style={{ marginTop: "20px" }}>
                  <ul className="footer-links-soi">
                    <li>
                      <a
                        href="https://www.facebook.com/grstudycircle"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <i className="fa fa-facebook"></i>
                        </div>
                      </a>

                      {/* <Link to="https://www.facebook.com/grstudycircle">
                        <i className="fa fa-facebook"></i>
                      </Link> */}
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCA7_kOpPAwdH2tIdXgxZEOQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <i className="fa fa-youtube-play"></i>
                        </div>
                      </a>
                      {/* <Link to={props.location.pathname}>
                        <i className="fa fa-youtube-play"></i>
                      </Link> */}
                    </li>
                    {/* <li>
                      <Link to={props.location.pathname}>
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://www.instagram.com/grstudycircle"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <i className="fa fa-instagram"></i>
                        </div>
                      </a>
                      {/* <Link to={props.location.pathname}>
                        <i className="fa fa-instagram"></i>
                      </Link> */}
                    </li>
                    <li>
                      <a
                        href="https://in.linkedin.com/in/gr-study-a13b551a9"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <i className="fa fa-linkedin"></i>
                        </div>
                      </a>
                      {/* <Link to={props.location.pathname}>
                        <i className="fa fa-linkedin"></i>
                      </Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-xs-12">
              <div className="widget clearfix">
                <div className="widget-title">
                  <h3>Information Link</h3>
                </div>
                <ul className="footer-links">
                  <li>
                    <Link to="">Home</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                    {/* <Link to="">Blog</Link> */}
                  </li>
                  <li>
                    {/* <Link to="/pricing">Pricing</Link> */}
                    <Link to="/course">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-xs-12">
              <div className="widget clearfix">
                <div className="widget-title">
                  <h3>Contact Details</h3>
                </div>

                <ul className="footer-links">
                  <li>
                    <a href="mailto:info@grstudycircle.com">
                      info@grstudycircle.com
                    </a>
                  </li>
                  <li>
                    <Link to="">https://griasacademy.com</Link>
                  </li>
                  <li>
                    D.No.1-2-288/23/3, Opp: NTR Stadium Indira Park Road,
                    Domalguda Road Hyderabad-500029
                  </li>
                  <li>
                    D. No. 1-1-18/57 , 2nd Floor, TRT 9, Ashok Nagar, Near RTC X
                    Road, Hyderabad - 500020
                  </li>
                  <li>
                    <a href="tel:9063345568">+91 9063 345 568</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copyrights">
        <div className="container">
          <div className="footer-distributed">
            <div className="footer-center">
              <p className="footer-company-name">
                All Rights Reserved. &copy; 2023{" "}
                <Link to="">GR IAS Academy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Link to="#" id="scroll-to-top" className="dmtop global-radius">
        <i className="fa fa-angle-up"></i>
      </Link>
    </div>
  );
};

export default withRouter(FooterComponent);
