import React, { useEffect, useState } from "react";
import Api from "../../api";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./TeacherDetails.css";

const TeacherDetails = (props) => {
  const [teacher, setTeacher] = useState({
    name: String,
    qualification: String,
    experience: String,
    highlights: String,
    subject: String,
    about: String,
  });
  const [index, setIndex] = useState([]);

  useEffect(() => {
    console.log(props);
    Api.post(
      "v1/teachers/teacher/",
      {},
      {
        params: {
          id: props.match.params.id,
        },
      }
    )
      .then((res) => {
        console.log("Teacher detail", res);
        setTeacher(res.data.result);
        // setIndex(res.data.courseIndex);
      })
      .catch((err) => {
        console.log("Error while retriving the course data.");
      });
  }, []);

  return (
    <div style={{ marginTop: "70px" }}>
      <div id="user-profile-2" className="user-profile">
        <div className="tabbable">
          <div className="tab-content no-border padding-24">
            <div id="home" className="tab-pane in active">
              <div className="row">
                <div className="col-xs-12 col-sm-3 center middle">
                  <span className="profile-picture">
                    <img
                      className="img-fluid"
                      src={"https://cdn.griasacademy.com" + teacher.profilePic}
                      alt=""
                    />
                  </span>

                  <div className="space space-4"></div>
                </div>

                <div className="col-xs-12 col-sm-9 my-4">
                  <div className="profile-user-info">
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Name </div>

                      <div className="profile-info-value">
                        <span>{teacher.name}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Qualification </div>

                      <div className="profile-info-value">
                        <span>{teacher.qualification}</span>
                      </div>
                    </div>

                    <div className="profile-info-row">
                      <div className="profile-info-name"> Experience </div>
                      <div className="profile-info-value">
                        <span>{teacher.experience} years</span>
                      </div>
                    </div>

                    <div className="profile-info-row">
                      <div className="profile-info-name"> Highlights </div>

                      <div className="profile-info-value">
                        <span>{teacher.highlights}</span>
                      </div>
                    </div>

                    <div className="profile-info-row">
                      <div className="profile-info-name"> Subjects </div>

                      <div className="profile-info-value">
                        <span>{teacher.subject}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-20"></div>

              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="widget-box transparent">
                    <div className="widget-body">
                      <div
                        className="profile-info-name"
                        style={{ fontSize: "19px" }}
                      >
                        {" "}
                        About me{" "}
                      </div>

                      <div className="widget-main">
                        <ul style={{ listStyle: "disc" }}>
                          {teacher.about
                            .toString()
                            .split(".")
                            .map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="feed" className="tab-pane">
              <div className="profile-feed row">
                <div className="col-sm-6">
                  <div className="profile-activity clearfix">
                    <div>
                      <img
                        className="pull-left"
                        alt="Alex Doe's avatar"
                        src="http://bootdey.com/img/Content/avatar/avatar1.png"
                      />
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      changed his profile photo.
                      <a href="#">Take a look</a>
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>
                        an hour ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <img
                        className="pull-left"
                        alt="Susan Smith's avatar"
                        src="http://bootdey.com/img/Content/avatar/avatar2.png"
                      />
                      <a className="user" href="#">
                        {" "}
                        Susan Smith{" "}
                      </a>
                      is now friends with Alex Doe.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>2
                        hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <i className="pull-left thumbicon fa fa-check btn-success no-hover"></i>
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      joined
                      <a href="#">Country Music</a>
                      group.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>5
                        hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <i className="pull-left thumbicon fa fa-picture-o btn-info no-hover"></i>
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      uploaded a new photo.
                      <a href="#">Take a look</a>
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>5
                        hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <img
                        className="pull-left"
                        alt="David Palms's avatar"
                        src="http://bootdey.com/img/Content/avatar/avatar3.png"
                      />
                      <a className="user" href="#">
                        {" "}
                        David Palms{" "}
                      </a>
                      left a comment on Alex's wall.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>8
                        hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="profile-activity clearfix">
                    <div>
                      <i className="pull-left thumbicon fa fa-pencil-square-o btn-pink no-hover"></i>
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      published a new blog post.
                      <a href="#">Read now</a>
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>
                        11 hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <img
                        className="pull-left"
                        alt="Alex Doe's avatar"
                        src="http://bootdey.com/img/Content/avatar/avatar4.png"
                      />
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      upgraded his skills.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>
                        12 hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <i className="pull-left thumbicon fa fa-key btn-info no-hover"></i>
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      logged in.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>
                        12 hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <i className="pull-left thumbicon fa fa-power-off btn-inverse no-hover"></i>
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      logged out.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>
                        16 hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>

                  <div className="profile-activity clearfix">
                    <div>
                      <i className="pull-left thumbicon fa fa-key btn-info no-hover"></i>
                      <a className="user" href="#">
                        {" "}
                        Alex Doe{" "}
                      </a>
                      logged in.
                      <div className="time">
                        <i className="ace-icon fa fa-clock-o bigger-110"></i>
                        16 hours ago
                      </div>
                    </div>

                    <div className="tools action-buttons">
                      <a href="#" className="blue">
                        <i className="ace-icon fa fa-pencil bigger-125"></i>
                      </a>

                      <a href="#" className="red">
                        <i className="ace-icon fa fa-times bigger-125"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-12"></div>

              <div className="center">
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-white btn-round"
                >
                  <i className="ace-icon fa fa-rss bigger-150 middle orange2"></i>
                  <span className="bigger-110">View more activities</span>

                  <i className="icon-on-right ace-icon fa fa-arrow-right"></i>
                </button>
              </div>
            </div>

            <div id="friends" className="tab-pane">
              <div className="profile-users clearfix">
                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar6.png"
                          alt="Bob Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-online"></span>
                          Bob Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">Content Editor</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 orange"></i>
                          <span className="green"> 20 mins ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar1.png"
                          alt="Rose Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-offline"></span>
                          Rose Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">Graphic Designer</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 grey"></i>
                          <span className="grey"> 30 min ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar2.png"
                          alt="Jim Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-busy"></span>
                          Jim Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">SEO &amp; Advertising</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 red"></i>
                          <span className="grey"> 1 hour ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar3.png"
                          alt="Alex Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-idle"></span>
                          Alex Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">Marketing</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 orange"></i>
                          <span className=""> 40 minutes idle </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar4.png"
                          alt="Phil Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-online"></span>
                          Phil Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">Public Relations</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 orange"></i>
                          <span className="green"> 2 hours ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar6.png"
                          alt="Susan Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-online"></span>
                          Susan Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">HR Management</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 orange"></i>
                          <span className="green"> 20 mins ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar1.png"
                          alt="Jennifer Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-offline"></span>
                          Jennifer Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">Graphic Designer</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 grey"></i>
                          <span className="grey"> 2 hours ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="itemdiv memberdiv">
                  <div className="inline pos-rel">
                    <div className="user">
                      <a href="#">
                        <img
                          src="http://bootdey.com/img/Content/avatar/avatar2.png"
                          alt="Alexa Doe's avatar"
                        />
                      </a>
                    </div>

                    <div className="body">
                      <div className="name">
                        <a href="#">
                          <span className="user-status status-offline"></span>
                          Alexa Doe
                        </a>
                      </div>
                    </div>

                    <div className="popover">
                      <div className="arrow"></div>

                      <div className="popover-content">
                        <div className="bolder">Accounting</div>

                        <div className="time">
                          <i className="ace-icon fa fa-clock-o middle bigger-120 grey"></i>
                          <span className="grey"> 4 hours ago </span>
                        </div>

                        <div className="hr dotted hr-8"></div>

                        <div className="tools action-buttons">
                          <a href="#">
                            <i className="ace-icon fa fa-facebook-square blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-twitter-square light-blue bigger-150"></i>
                          </a>

                          <a href="#">
                            <i className="ace-icon fa fa-google-plus-square red bigger-150"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hr hr10 hr-double"></div>

              <ul className="pager pull-right">
                <li className="previous disabled">
                  <a href="#">← Prev</a>
                </li>

                <li className="next">
                  <a href="#">Next →</a>
                </li>
              </ul>
            </div>

            <div id="pictures" className="tab-pane">
              <ul className="ace-thumbnails">
                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/1/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/2/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/3/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/4/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/5/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/6/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/7/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>

                <li>
                  <a href="#" data-rel="colorbox">
                    <img
                      alt="150x150"
                      src="http://lorempixel.com/200/200/nature/1/"
                    />
                    <div className="text">
                      <div className="inner">Sample Caption on Hover</div>
                    </div>
                  </a>

                  <div className="tools tools-bottom">
                    <a href="#">
                      <i className="ace-icon fa fa-link"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-paperclip"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-pencil"></i>
                    </a>

                    <a href="#">
                      <i className="ace-icon fa fa-times red"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(TeacherDetails);
