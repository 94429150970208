import React, { useEffect, useState } from "react";
import AboutDetailComponent from "./About-detail";
import Loader from "../Loader/Loader";
import { Helmet } from "react-helmet";
import RequestDemo from "../RequestDemo/RequestDemo";
import { Link } from "react-router-dom";

const AboutWrapperComponent = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    setShowLoader(false);
    // document.title = "About Us - Gr Study Circle";
  }, []);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="adjust-top">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Gr Study Circle</title>
        <meta
          name="keywords"
          content="best ias academy in hyderabad,civils coaching in hyderabad,gr ias academy"
        />
        <meta
          name="og:keywords"
          content="best ias academy in hyderabad,civils coaching in hyderabad,gr ias academy"
        />
      </Helmet>
      <div className="all-title-box">
        <div className="container text-center">
          <h1>About Us</h1>
        </div>
        <div
          className="big-tagline"
          style={{
            textAlign: "right",
            marginRight: "3em",
            marginTop: "3em",
          }}
        >
          <Link
            to="/about"
            onClick={() => setShowPopUp(true)}
            className="hover-btn-new"
          >
            <span>Request Demo</span>
          </Link>
        </div>
      </div>
      <AboutDetailComponent />
      <RequestDemo visible={showPopUp} closePopup={() => setShowPopUp(false)} />
    </div>
  );
};

export default AboutWrapperComponent;
