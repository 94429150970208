import React from 'react';
import Modal from 'react-awesome-modal';


const Popup = (props) => {
    return (
        <Modal visible={props.visible} height={props.height} width = {props.width} effect={props.effect} onClickAway={props.clickAway} >
            {props.children}
        </Modal>
    );
}

export default Popup;