import React, { useState, useEffect } from "react";
import HomeCarousel from "../Home-Carosal/HomeCarousel";
import AboutComponent from "../About/About-session";
import HistoryComponent from "../History/History";
import SubscriptionComponent from "../Subscription/Subscription";
import Testimonialscomponent from "../Testimonials/Testimonials";
import Loader from "../Loader/Loader";

const HomeComponent = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(false);
  }, []);

  return showLoader ? (
    <Loader />
  ) : (
    <div style={{ marginTop: "70px" }}>
      <HomeCarousel />
      <AboutComponent />
      <HistoryComponent />
      <SubscriptionComponent />
      {/* <Testimonialscomponent/> */}
    </div>
  );
};

export default HomeComponent;
