import React from "react";

import "./InnerLoader.css";

const InnerLoder = () => {
  return (
    <div
      className="page-content page-container"
      id="page-content"
      style={{ backgroundColor: "#ffffff" }}
    >
      <div className="padding">
        <div className="row container d-flex justify-content-center">
          <div className="col-md-4 col-sm-6 grid-margin stretch-card">
            <div className="loader-demo-box">
              <div className="jumping-dots-loader">
                {" "}
                <span></span>{" "}
                <span style={{ backgroundColor: "rgb(76 90 125)" }}></span>{" "}
                <span></span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerLoder;
