import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Testimonialscomponent from "../Testimonials/Testimonials";
import Loader from "../Loader/Loader";
import Api from "../../api";
import InnerLoder from "../Loader-inner/InnerLoader";
import { Helmet } from "react-helmet";
import RequestDemo from "../RequestDemo/RequestDemo";

const TeachersComponent = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [teachers, setTeachers] = useState([{}]);
  const [profilePic, setProfilePic] = useState("");
  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    document.title = "Our Faculty - Gr Study Circle";
    Api.get("v1/teachers/")
      .then((res) => {
        console.log("res: all teachers ", res);
        setTeachers(res.data.result);
        preFetchImages(res.data.result);
        setShowLoader(false);
      })
      .catch((err) => console.log("Error while retriving courses from data"));
  }, []);

  const preFetchImages = (coursesList) => {
    let imagesArray = coursesList.map((course) => course.profilePic);
    caheImage(removeDuplicatesInArray(imagesArray), 0);
  };

  const caheImage = (imagesArray, ind) => {
    if (ind < imagesArray.length) {
      imgPromise(imagesArray[ind])
        .then(() => {
          caheImage(imagesArray, ++ind);
        })
        .catch((err) => {
          caheImage(imagesArray, ind);
        });
    } else {
      console.log("All the images are loaded, stopping the spinner");
      setTimeout(() => {
        setIsAllImagesLoaded(true);
      }, 3000);
    }
  };

  const imgPromise = (src) => {
    console.log(src);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = "https://cdn.griasacademy.com" + src;
      img.onload = resolve();
      img.onerror = reject();
    });
  };

  const removeDuplicatesInArray = (imgArray) => {
    let filteredImagesArray = imgArray.filter(
      (img, index, imgArray) => imgArray.indexOf(img) === index
    );
    return filteredImagesArray;
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className="adjust-top">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Faculty - Gr Study Circle</title>
        <meta
          name="keywords"
          content="best upsc coaching staff,best upsc caoching faculty,top upsc teaching staff,best ias teaching faculty,top ias coaching staff ,best ias coaching faculty"
        />
        <meta
          name="og:keywords"
          content="best upsc coaching staff,best upsc caoching faculty,top upsc teaching staff,best ias teaching faculty,top ias coaching staff ,best ias coaching faculty"
        />
      </Helmet>
      <div className="all-title-box">
        <div className="container text-center">
          <h1>Faculty</h1>
        </div>
        <div
          className="big-tagline"
          style={{
            textAlign: "right",
            marginRight: "3em",
            marginTop: "3em",
          }}
        >
          <Link
            to="/faculty"
            onClick={() => setShowPopUp(true)}
            className="hover-btn-new"
          >
            <span>Request Demo</span>
          </Link>
        </div>
      </div>
      {!isAllImagesLoaded ? (
        <InnerLoder />
      ) : (
        <div id="teachers" className="section wb">
          <div className="container">
            <div className="row">
              {teachers.map((teacher, index) => {
                return (
                  <div className="col-lg-3 col-md-6 col-12 hover-me">
                    <div className="our-team">
                      <div className="team-img">
                        <img
                          src={
                            "https://cdn.griasacademy.com" + teacher.profilePic
                          }
                          alt="teacher-1"
                          className="img-fluid"
                        />
                        {/* <div className="social">
                      <ul>
                        <li>
                          <Link to="" className="fa fa-facebook"></Link>
                        </li>
                        <li>
                          <Link to="" className="fa fa-twitter"></Link>
                        </li>
                        <li>
                          <Link to="" className="fa fa-linkedin"></Link>
                        </li>
                        <li>
                          <Link to="" className="fa fa-skype"></Link>
                        </li>
                      </ul>
                    </div> */}
                      </div>
                      <div className="team-content">
                        <h3 className="title">{teacher.name}</h3>
                        <span className="post">{teacher.qualification}</span>

                        <Link
                          to={
                            "/faculty/" + teacher.teacherId + "/" + teacher.name
                          }
                          title=""
                          style={{ color: "#eea412" }}
                          // className="teacher-sample-demo"
                        >
                          Click to view profile
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <RequestDemo visible={showPopUp} closePopup={() => setShowPopUp(false)} />
    </div>
  );
};

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-02.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Kristiana</h3>
                  <span className="post">Web Designer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-03.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Steve Thomas</h3>
                  <span className="post">Web Developer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-04.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Miranda joy</h3>
                  <span className="post">Web Developer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-01.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Williamson</h3>
                  <span className="post">Web Developer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-02.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Kristiana</h3>
                  <span className="post">Web Designer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-03.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Steve Thomas</h3>
                  <span className="post">Web Developer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}

{
  /* <div className="col-lg-3 col-md-6 col-12 hover-me">
              <div className="our-team">
                <div className="team-img">
                  <img src="images/team-04.png" alt="teacher-2" />
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="" className="fa fa-facebook"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-twitter"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-linkedin"></Link>
                      </li>
                      <li>
                        <Link to="" className="fa fa-skype"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h3 className="title">Miranda joy</h3>
                  <span className="post">Web Developer</span>
                  <Link to="/about-teacher/1" className="teacher-sample-demo">
                    Click to view profile
                  </Link>
                </div>
              </div>
            </div> */
}
//           </div>
//         </div>
//       </div>
//       <Testimonialscomponent />
//     </div>
//   );
// };

export default TeachersComponent;
