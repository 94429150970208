import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import ReactHtmlParser from "react-html-parser";
import Api from "../../api";
import { Helmet } from "react-helmet";

const BlogDetailComponent = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [blog, setBlog] = useState({});

  const [index, setIndex] = useState([]);
  const [blogDetails, setBlogDetails] = useState({
    date: 0,
    modifiedDate: "",
    monthName: "",
    dayToDisplay: 0,
  });

  useEffect(() => {
    console.log("params id", props.match.params.id);
    Api.post(
      "v1/blogs/blog/",
      {},
      {
        params: {
          id: props.match.params.id,
        },
      }
    )
      .then((res) => {
        console.log("blog detail", res);
        setBlog(res.data.result);
        setIndex(res.data.courseIndex);
        // setBlogDetails((prevState) => ({
        //   ...prevState,
        //   date: res.data.result.cd,
        // }));
        dateDisplay(res.data.result.cd);
      })
      .catch((err) => {
        console.log("Error while retriving the blog data.");
      });

    // setDate(date);

    window.scrollTo(0, 0);

    setShowLoader(false);
  }, []);

  const dateDisplay = (longDate) => {
    console.log("cd", longDate);
    let displayDate = new Date(longDate);
    let month = "" + (displayDate.getMonth() + 1);
    let day = "" + displayDate.getDate();
    let year = displayDate.getFullYear();
    let shortMonth = displayDate.toLocaleString("default", { month: "short" });

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    displayDate = [year, month, day].join("-");
    setBlogDetails((prevState) => ({
      ...prevState,
      modifiedDate: displayDate,
      monthName: shortMonth,
      dayToDisplay: day,
    }));
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className="adjust-top">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Blog - Gr IAS Academy</title>
        <meta name="keywords" content={blog.metaKeywords} />
        <meta name="og:keywords" content={blog.metaKeywords} />
      </Helmet>
      {/* <div className="all-title-box">
                <div className="container text-center">
                    <h1>Blog Single<span className="m_1">Lorem Ipsum dolroin gravida nibh vel velit.</span></h1>
                </div>
            </div> */}

      <div id="overviews" className="section wb">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 blog-post-single">
              <div className="blog-item">
                <div className="image-blog">
                  <img
                    src={"https://cdn.griasacademy.com" + blog.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="post-content">
                  <div className="post-date">
                    <span className="day">{blogDetails.dayToDisplay}</span>
                    <span className="month">{blogDetails.monthName}</span>
                  </div>
                  <div className="meta-info-blog">
                    <span>
                      <i className="fa fa-calendar"></i>{" "}
                      <Link to="">{blogDetails.modifiedDate}</Link>{" "}
                    </span>
                    <span>
                      <i className="fa fa-tag"></i>{" "}
                      <Link to="">{blog.category}</Link>{" "}
                    </span>
                    {/* <span>
                      <i className="fa fa-comments"></i>{" "}
                      <Link to="">12 Comments</Link>
                    </span> */}
                  </div>
                  <div className="blog-title">
                    <h3
                      style={{ color: "#0c2e60", margin: "10px 0px 10px 0px" }}
                    >
                      {blog.title}
                    </h3>
                  </div>
                  <div
                    className="blog-desc"
                    style={{ padding: "0px 20px 0px 20px" }}
                  >
                    {ReactHtmlParser(blog.description)}
                  </div>
                </div>
              </div>

              {/* <div className="blog-author">
                <div className="author-bio">
                  <h3 className="author_name">
                    <Link to="">Tom Jobs</Link>
                  </h3>
                  <h5>
                    CEO at <Link to="">SmartEDU</Link>
                  </h5>
                  <p className="author_det">
                    Lorem ipsum dolor sit amet, consectetur adip, sed do eiusmod
                    tempor incididunt ut aut reiciendise voluptat maiores alias
                    consequaturs aut perferendis doloribus omnis saperet docendi
                    nec, eos ea alii molestiae aliquand.
                  </p>
                </div>
                <div className="author-desc">
                  <img src="../images/author.jpg" alt="about author" />
                  <ul className="author-social">
                    <li>
                      <Link to="">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="fa fa-skype"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="col-lg-3 col-12 right-single">
              {/* <div className="widget-search">
                <div className="site-search-area">
                  <form method="get" id="site-searchform" action="#">
                    <div>
                      <input
                        className="input-text form-control"
                        name="search-k"
                        id="search-k"
                        placeholder="Search keywords..."
                        type="text"
                      />
                      <input id="searchsubmit" value="Search" type="submit" />
                    </div>
                  </form>
                </div>
              </div> */}
              <div className="widget-categories">
                <h3 className="widget-title">Categories</h3>
                <ul>
                  <li>
                    <Link to="">Civils Mains</Link>
                  </li>
                  <li>
                    <Link to="">Civils Prelims</Link>
                  </li>
                  <li>
                    <Link to="">Current Affairs</Link>
                  </li>
                  <li>
                    <Link to="">General</Link>
                  </li>
                </ul>
              </div>
              {/* <div className="widget-tags">
                <h3 className="widget-title">Search Tags</h3>
                <ul className="tags">
                  <li>
                    <Link to="">
                      <b>business</b>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <b>jquery</b>
                    </Link>
                  </li>
                  <li>
                    <Link to="">corporate</Link>
                  </li>
                  <li>
                    <Link to="">portfolio</Link>
                  </li>
                  <li>
                    <Link to="">css3</Link>
                  </li>
                  <li>
                    <Link to="">
                      <b>theme</b>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <b>html5</b>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <b>mysql</b>
                    </Link>
                  </li>
                  <li>
                    <Link to="">multipurpose</Link>
                  </li>
                  <li>
                    <Link to="">responsive</Link>
                  </li>
                  <li>
                    <Link to="">premium</Link>
                  </li>
                  <li>
                    <Link to="">javascript</Link>
                  </li>
                  <li>
                    <Link to="">
                      <b>Best jQuery</b>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailComponent;
