import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import GoogleAPI from "../GoogleMaps/GoogleAPI";
import Axios from "../../api";

import "./Contact.css";
import CustomAlert from "../sweet-alert/sweetAlert";

const ContactComponent = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [contactForm, setContactForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [contactFormError, setContactFormError] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneNumberError: false,
    messageError: false,
  });

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const phoneNumberValidateHandler = (num) => {
    setContactForm((prevState) => ({ ...prevState, phoneNumber: num }));
    if ((num !== "" && !/([9876]{1})(\d{9})$/.test(num)) || num === "") {
      setContactFormError((prevState) => ({
        ...prevState,
        phoneNumberError: true,
      }));
      return false;
    } else {
      setContactFormError((prevState) => ({
        ...prevState,
        phoneNumberError: false,
      }));
      return true;
    }
  };

  const emailValidateHandler = (mail) => {
    setContactForm((prevState) => ({ ...prevState, email: mail }));
    if (
      mail === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
    ) {
      setContactFormError((prevState) => ({ ...prevState, emailError: true }));
      return false;
    } else {
      setContactFormError((prevState) => ({ ...prevState, emailError: false }));
      return true;
    }
  };

  const firstNameValidateHandler = (fName) => {
    setContactForm((prevState) => ({ ...prevState, firstName: fName }));
    if (fName.trim() === "") {
      setContactFormError((prevState) => ({
        ...prevState,
        firstNameError: true,
      }));
      return false;
    } else {
      setContactFormError((prevState) => ({
        ...prevState,
        firstNameError: false,
      }));
      return true;
    }
  };

  const lastNameValidateHandler = (fName) => {
    setContactForm((prevState) => ({ ...prevState, lastName: fName }));
    if (fName.trim() === "") {
      setContactFormError((prevState) => ({
        ...prevState,
        lastNameError: true,
      }));
      return false;
    } else {
      setContactFormError((prevState) => ({
        ...prevState,
        lastNameError: false,
      }));
      return true;
    }
  };

  const messageValidateHandler = (fName) => {
    setContactForm((prevState) => ({ ...prevState, message: fName }));
    if (fName.trim() === "") {
      setContactFormError((prevState) => ({
        ...prevState,
        messageError: true,
      }));
      return false;
    } else {
      setContactFormError((prevState) => ({
        ...prevState,
        messageError: false,
      }));
      return true;
    }
  };

  const validateAndSubmit = (event) => {
    event.preventDefault();
    setIsProcessingRequest(true);
    if (
      firstNameValidateHandler(contactForm.firstName) &&
      lastNameValidateHandler(contactForm.lastName) &&
      emailValidateHandler(contactForm.email) &&
      phoneNumberValidateHandler(contactForm.phoneNumber) &&
      messageValidateHandler(contactForm.message)
    ) {
      const contactObject = {
        full_name: contactForm.firstName + " " + contactForm.lastName,
        emailId: contactForm.email,
        mobileNo: contactForm.phoneNumber,
        message: contactForm.message,
      };
      console.log(
        "form validation is success. and mesage posted successfully.",
        contactObject
      );
      // fetch("http://www.grstudycircle.com/api/v1/users/contactUs", {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json, text/plain, */*",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(contactObject),
      // })
      //   .then((res) => res.json())
      //   .then((res) => console.log(res));
      Axios.post("v1/contactus/", contactObject)
        .then((res) => {
          setIsProcessingRequest(false);
          setSweetAlert({
            show: true,
            title: "Success",
            body:
              "Thank you for contacting us.  Please check your email for further details, we will get back to you shortly.",
          });
          console.log(
            "Received your concert, we will reachout to you soon via mail."
          );
        })
        .catch((err) => {
          console.log(
            "Error occured while posting the data, pelase try again later. ",
            err
          );
          setSweetAlert({
            show: true,
            title: "Failed",
            body: "Something went wrong, please try again later.",
          });
          setIsProcessingRequest(false);
        });
    } else {
      console.log(
        "All the fields are mandatory, please check and try again. ",
        event
      );
      setIsProcessingRequest(false);
      alert(
        "All the fields are maindatory, please check all the fields and try again!"
      );
    }
  };

  useEffect(() => {
    setShowLoader(false);
    document.title = "Contact Us - Gr Study Circle";
  }, []);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="adjust-top">
      {/* <div className="all-title-box">
          <div className="container text-center">
            <h1>Contact<span className="m_1">Lorem Ipsum dolroin gravida nibh vel velit.</span></h1>
          </div>
        </div> */}

      <div id="contact" className="section wb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Need Help? Sure we are Online!</h3>
            {/* <p className="lead">
              Let us give you more details about the special offer website you
              want us. Please fill out the form below. <br />
              We have million of website owners who happy to work with us!
            </p> */}
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-md-12 col-sm-12"
              style={{ marginBottom: "50px" }}
            >
              <div className="contact_form">
                <div id="message"></div>
                <form
                  id="contactform"
                  className=""
                  name="contactform"
                  onSubmit={(event) => validateAndSubmit(event)}
                >
                  <div className="row row-fluid">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="First Name"
                        className={
                          contactFormError.firstNameError &&
                          contactForm.firstName !== ""
                            ? "inputFieldError form-control"
                            : !contactFormError.firstNameError &&
                              contactForm.firstName !== ""
                            ? "inputFieldSuccess form-control"
                            : "form-control"
                        }
                        value={contactForm.firstName}
                        onChange={(event) =>
                          firstNameValidateHandler(event.target.value)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Last Name"
                        className={
                          contactFormError.lastNameError &&
                          contactForm.lastName !== ""
                            ? "inputFieldError form-control"
                            : !contactFormError.lastNameError &&
                              contactForm.lastName !== ""
                            ? "inputFieldSuccess form-control"
                            : "form-control"
                        }
                        value={contactForm.lastName}
                        onChange={(event) =>
                          lastNameValidateHandler(event.target.value)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        className={
                          contactFormError.emailError &&
                          contactForm.email !== ""
                            ? "inputFieldError form-control"
                            : !contactFormError.emailError &&
                              contactForm.email !== ""
                            ? "inputFieldSuccess form-control"
                            : "form-control"
                        }
                        value={contactForm.email}
                        onChange={(event) =>
                          emailValidateHandler(event.target.value)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Your Phone"
                        className={
                          contactFormError.phoneNumberError &&
                          contactForm.phoneNumber !== ""
                            ? "inputFieldError form-control"
                            : !contactFormError.phoneNumberError &&
                              contactForm.phoneNumber !== ""
                            ? "inputFieldSuccess form-control"
                            : "form-control"
                        }
                        value={contactForm.phoneNumber}
                        onChange={(event) =>
                          phoneNumberValidateHandler(event.target.value)
                        }
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        name="comments"
                        id="comments"
                        rows="6"
                        placeholder="Give us more details.."
                        className={
                          contactFormError.messageError &&
                          contactForm.message !== ""
                            ? "inputFieldError form-control"
                            : !contactFormError.messageError &&
                              contactForm.message !== ""
                            ? "inputFieldSuccess form-control"
                            : "form-control"
                        }
                        value={contactForm.message}
                        onChange={(event) =>
                          messageValidateHandler(event.target.value)
                        }
                      ></textarea>
                    </div>
                    <div className="text-center pd">
                      <input
                        type="submit"
                        className="btn btn-primary"
                        disabled={isProcessingRequest}
                        value={
                          isProcessingRequest
                            ? "Submiting Request..."
                            : "Submit"
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 address-wrapper">
              <div className="branchName">Domalguda</div>
              <div className="phone item">
                <span className="icon">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </span>
                <p>
                  D.No.1-2-288/23/3, Opp: NTR Stadium Indira Park Road,
                  Domalguda Road Hyderabad-500029
                </p>
              </div>
              <div className="phone item">
                <span className="icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </span>
                <p>+91 9063 345 568</p>
              </div>
              <div className="mail item">
                <span className="icon">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
                <p>info@grstudycircle.com</p>
              </div>
              <div className="timings item">
                <span className="icon">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                </span>
                <p>Mon - Fri : 08:00am - 08:00pm</p>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 address-wrapper">
              <div className="branchName">RTC X Roads</div>
              <div className="phone item">
                <span className="icon">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </span>
                <p>
                  D. No. 1-1-18/57 , 2nd Floor, TRT 9, Ashok Nagar, Near RTC X
                  Road, Hyderabad - 500020
                </p>
              </div>
              <div className="phone item">
                <span className="icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </span>
                <p>+91 9063 345 568</p>
              </div>
              <div className="mail item">
                <span className="icon">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
                <p>info@grstudycircle.com</p>
              </div>
              <div className="timings item">
                <span className="icon">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                </span>
                <p>Mon - Fri : 08:00am - 08:00pm</p>
              </div>
            </div>
            {/* <div
              className="col-xl-6 col-md-12 col-sm-12 my-4"
              style={{ minHeight: "200px" }}
            >
              <div className="map-box">
                <GoogleAPI />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <CustomAlert
        show={sweetAlert.show}
        title={sweetAlert.title}
        body={sweetAlert.body}
        close={() =>
          setSweetAlert((prevState) => ({ ...prevState, show: false }))
        }
      />
    </div>
  );
};

export default ContactComponent;
