import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Api from "../../api";

import "./courseDetail.css";
import Axios from "axios";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

const CourseDetail = (props) => {
  const [course, setCourse] = useState({});
  const [index, setIndex] = useState([]);

  useEffect(() => {
    console.log("params id", typeof props.match.params.id);
    Api.get("v1/Courses/course", {
      params: {
        id: props.match.params.id,
      },
    })
      .then((res) => {
        console.log("course detail", res);
        setCourse(res.data.result);
        setIndex(res.data.courseIndex);
        console.log("course detail", res.data.result.title);
        document.title = res.data.result.title;
      })
      .catch((err) => {
        console.log("Error while retriving the course data.");
      });
    // console.log("props", props);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="adjust-top-course">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{course.title}</title>
        <meta name="keywords" content={course.metaKeywords} />
        <meta name="og:keywords" content={course.metaKeywords} />
      </Helmet>
      {/* <section className="breadcrumb breadcrumb_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner text-center">
                <div className="breadcrumb_iner_item">
                  <h2>Course Details</h2>
                  <p>
                    <Link to="/" className="url">
                      Home
                    </Link>
                    <span>/</span>
                    <Link to="/course" className="url">
                      Course
                    </Link>
                    <span>/</span>
                    <Link to={props.match.url} className="url">
                      Course Details
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="course_details_area section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 course_details_left">
              <div className="main_image">
                <img
                  className="img-fluid"
                  src={"https://cdn.griasacademy.com" + course.coursePicture}
                  alt=""
                />
              </div>
              <div className="content_wrapper">
                <h4 className="title_top">{course.name}</h4>
                <h4 className="title">Objectives</h4>
                <div className="content">
                  The Indian Administrative Service is the premier
                  administrative civil service of the Government of India and is
                  the backbone of Indian governance. Hierarchy-wise, IAS is the
                  highest administrative post among the 24 services like IPS,
                  IFS etc. Officially it is called as Civil Services Examination
                  (CSE), which is conducted every year by t the Union Public
                  Service Commission (UPSC).Our Main objective is to hand hold
                  in the journey of your success.
                </div>
                <h4 className="title">Eligibility</h4>

                <div>
                  <table className="table table-bordered">
                    {/* <caption>ELIGIBILITY</caption> */}
                    <tbody>
                      <tr>
                        <td class="fb">EDUCATION</td>
                        <td colspan="3">
                          Any Degree (Recognized by UGC/AICTE)
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" class="fb">
                          AGE
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="fb">Minimum Age</td>
                        <td class="fb">Maximum Age</td>
                        <td class="fb">No. of attempts</td>
                      </tr>
                      <tr>
                        <td class="fb">GENERAL</td>
                        <td>21</td>
                        <td>32</td>
                        <td>6</td>
                      </tr>
                      <tr>
                        <td class="fb">OBC</td>
                        <td>21</td>
                        <td>35</td>
                        <td>9</td>
                      </tr>
                      <tr>
                        <td class="fb">SC &amp; ST</td>
                        <td>21</td>
                        <td>37</td>
                        <td>No restriction</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>{ReactHtmlParser(course.about)}</div>
                <div>{ReactHtmlParser(course.syllabus)}</div>

                {/* <div className="content">{course.description}</div> */}
                {/* <h4 className="title">Course Outline</h4>
                <div className="content">
                  <ul className="course_list">
                    {index.map((item, index) => {
                      return (
                        <li
                          className="justify-content-between align-items-center d-flex"
                          key={index}
                        >
                          <p>{item}</p>
                          <Link className="btn_2 text-uppercase" to="">
                            View Details
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 right-contents">
              <div className="sidebar_top">
                <ul>
                  <li>
                    <p>Trainers Name</p>
                    <span className="color">{course.faculty}</span>
                  </li>
                  <li>
                    <p>Course Fee </p>
                    <span>{course.fee}/-</span>
                  </li>
                  <li>
                    <p>Available Seats</p>
                    <span>{course.availableSeats}</span>
                  </li>
                  <li>
                    <p>Duration </p>
                    <span>{course.duration}</span>
                  </li>
                </ul>
                <Link to="/paycheckout" className="btn_1 d-block">
                  Enroll the course
                </Link>
              </div>
              {/* <h4 className="title">Reviews</h4>
                            <div className="content">
                                <div className="review-top row pt-40">
                                    <div className="col-lg-12">
                                        <h6 className="mb-15">Provide Your Rating</h6>
                                        <div className="d-flex flex-row reviews justify-content-between">
                                            <span>Quality</span>
                                            <div className="rating">
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/star.svg" alt="" /></Link>
                                            </div>
                                            <span>Outstanding</span>
                                        </div>
                                        <div className="d-flex flex-row reviews justify-content-between">
                                            <span>Puncuality</span>
                                            <div className="rating">
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/star.svg" alt="" /></Link>
                                            </div>
                                            <span>Outstanding</span>
                                        </div>
                                        <div className="d-flex flex-row reviews justify-content-between">
                                            <span>Quality</span>
                                            <div className="rating">
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                <Link to=""><img src="/images/course-detail/icon/star.svg" alt="" /></Link>
                                            </div>
                                            <span>Outstanding</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="feedeback">
                                    <h6>Your Feedback</h6>
                                    <textarea name="feedback" className="form-control" cols="10" rows="10"></textarea>
                                    <div className="mt-10 text-right">
                                        <Link to="" className="btn_1">Read more</Link>
                                    </div>
                                </div>
                                <div className="comments-area mb-30">
                                    <div className="comment-list">
                                        <div className="single-comment single-reviews justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src="/images/course-detail/cource/cource_1.png" alt="" />
                                                </div>
                                                <div>
                                                    <h5><Link to="">Emilly Blunt</Link>
                                                    </h5>
                                                    <div className="rating">
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/star.svg" alt="" /></Link>
                                                    </div>
                                                    <p className="comment">Blessed made of meat doesn't lights doesn't was dominion and sea earth form</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-list">
                                        <div className="single-comment single-reviews justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src="/images/course-detail/cource/cource_2.png" alt="" />
                                                </div>
                                                <div>
                                                    <h5><Link to="">Elsie Cunningham</Link>
                                                    </h5>
                                                    <div className="rating">
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/star.svg" alt="" /></Link>
                                                    </div>
                                                    <p className="comment">Blessed made of meat doesn't lights doesn't was dominion and sea earth form</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-list">
                                        <div className="single-comment single-reviews justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src="/images/course-detail/cource/cource_3.png" alt="" />
                                                </div>
                                                <div>
                                                    <h5><Link to="">Maria Luna</Link>
                                                    </h5>
                                                    <div className="rating">
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/color_star.svg" alt="" /></Link>
                                                        <Link to=""><img src="/images/course-detail/icon/star.svg" alt="" /></Link>
                                                    </div>
                                                    <p className="comment">Blessed made of meat doesn't lights doesn't was dominion and sea earth form</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(CourseDetail);
