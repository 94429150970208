import Axios from "axios";

export default Axios.create({
  // baseURL: "https://grsc.wedonate.live/api/",
  //baseURL: "http://localhost:5532/api/",
  baseURL: "https://www.griasacademy.com/api/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    //    Authorization: "Phanindra'sauthtoken",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //"Access-Control-Allow-Headers":
    // "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  },
});
