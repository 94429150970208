import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const SnackBar =  (props) => {

    return <div>
        <div id="snackbar" className={props.visibleSnackBar?"show":""}>{props.message}</div>
    </div>
}

export default SnackBar;