import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Popup from "../Popup/Popup";
import CustomAlert from "../sweet-alert/sweetAlert";

import Api from "../../api";
import "./talent.css";
// import "./opensans-font.css";

import SnackBar from "../Snackbar/Snackbar";

const Talent = (prop) => {
  // useEffect(() => {
  //     openPopup(prop);
  // });

  // const [visiblePopup, setVisiblePopup] = useState(false);
  // const [visibleRefferer, setVisibleRefferer] = useState("login");

  const [snackBarProps, setSnackBarProps] = useState({
    visible: false,
    message: "",
  });

  // const [snackBarProps, setSnackBarProps] = useState({
  //   visible: false,
  //   message: "",
  // });

  // const [txnId, setTxnId] = useState("");

  const [showSweetAlert, setShowSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const [checkoutFields, setCheckoutFields] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    course: "",
    insituteName: "",
    qualification: "",
    paymentType: "Test",
    amount: 1,
    dateofExam: "12-June-2022",
  });

  const [transactionFields, setTransactionFields] = useState({
    razorpay_order_id: "",
    razorpay_payment_id: "",
    razorpay_signature: "",
  });

  const [orderIdFields, setOrderIdFields] = useState({
    orderId: "",
  });

  const [checkoutFieldsError, setCheckoutFieldsError] = useState({
    fullNameError: false,
    emailError: false,
    mobileNumberError: false,
    courseError: false,
    insituteName: false,
    qualificationError: false,
    amountError: false,
    dateofExam: false,
  });

  const checkoutValidateHandler = (event) => {
    event.preventDefault();
    setIsProcessingRequest(true);
    // console.log(fullName + " " + rUserName + " " + rPassword + " " + mNumber);
    if (
      fullNameValidateHandler(checkoutFields.fullName) &&
      mobileNumberValidateHandler(checkoutFields.mobileNumber) &&
      emailValidateHandler(checkoutFields.email)
    ) {
      const checkoutObject = {
        fullName: checkoutFields.fullName,
        phoneNumber: checkoutFields.mobileNumber,
        email: checkoutFields.email,
        course: checkoutFields.course,
        insituteName: checkoutFields.insituteName,
        qualification: checkoutFields.qualification,
        paymentType: checkoutFields.paymentType,
        amount: checkoutFields.amount,
      };
      //console.log("check object is ", checkoutObject);
      // Api.post("v1/users/createorder", checkoutObject)
      Api.post("v1/users/createorder", checkoutObject)

        .then((res) => {
          // setSnackBarProps({
          //   visible: true,
          //   message: "User registered successfully!",
          // });
          if (res.data.result.orderId != "") {
            // console.log("txn id" + res.data.result.receipt);
            // setTxnId(res.data.result.receipt);
            razorPayHandler(res.data.result.orderId, res.data.result.receipt);
          } else {
            console.log("no order id found");
            setSweetAlert({
              show: true,
              title: "Failed",
              body: "Order not created , please try again.",
            });
            setIsProcessingRequest(false);
          }
          // setOrderIdFields({
          //   orderId: res.data.result.orderId,
          // });
          // console.log("orderId", orderIdFields.orderId);
        })
        .catch((err) => {
          alert("Please try again.");
          setIsProcessingRequest(false);
        });

      // razorPayHandler(orderIdFields.orderId);

      // if (orderIdFields.orderId != "") {
      //   razorPayHandler(orderIdFields.orderId);
      // } else {
      //   console.log("No order id from backend");
      // }
    } else {
      console.log("some thing went wrong please try again later.");
      setIsProcessingRequest(false);
    }
    return false;
  };

  const checkoutValidateHandlerWithoutPay = (event) => {
    event.preventDefault();
    setIsProcessingRequest(true);
    // console.log(fullName + " " + rUserName + " " + rPassword + " " + mNumber);
    if (
      fullNameValidateHandler(checkoutFields.fullName) &&
      mobileNumberValidateHandler(checkoutFields.mobileNumber) &&
      emailValidateHandler(checkoutFields.email)
    ) {
      const checkoutObject = {
        fullName: checkoutFields.fullName,
        phoneNumber: checkoutFields.mobileNumber,
        email: checkoutFields.email,
        course: checkoutFields.course,
        qualification: checkoutFields.qualification,
        testDate: checkoutFields.dateofExam,
        // paymentType: checkoutFields.paymentType,
        // amount: checkoutFields.amount,
      };
      //console.log("check object is ", checkoutObject);
      // Api.post("v1/users/createorder", checkoutObject)
      Api.post("v1/users/screening", checkoutObject)

        .then((res) => {
          setSweetAlert({
            show: true,
            title: "Congratulations!",
            body:
              "Thank you for your Registering for screening Test, You are now directly eligible for 10% Discount on Course Fee by registering for this Scholarship Test and also Fee concession upto 75% based on your score card.",
          });
        })
        .catch((err) => {
          setSweetAlert({
            show: true,
            title: "Failed",
            body: "Something went wrong , please try again",
          });
          setIsProcessingRequest(false);
        });
    } else {
      setSweetAlert({
        show: true,
        title: "Failed",
        body: "Please fill all the mandatory fields",
      });
      setIsProcessingRequest(false);
    }
    return false;
  };

  const razorPayHandler = (orderId, txnId) => {
    // console.log("inside razor pay handler", orderId);
    let options = {
      // key: "rzp_test_jwv3SEVlPcoh8v",
      key: "rzp_live_RIJcbtFMZHeY9q",
      amount: checkoutFields.amount, // 2000 paise = INR 20, amount in paisa
      name: "GRSC",
      description: "Exam Purchase",
      order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      //   image: "/your_logo.png",
      handler: function (response) {
        // alert(response);
        // console.log("Payment response from razor pay", response);
        if (response != null) {
          // setTxnId(response.result.receipt);
          const transactionObject = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            // transaction_id: response.txnid,
          };

          Api.post("v1/users/verifypayment", transactionObject)
            .then((res) => {
              // console.log("Transaction done.", res);
              setIsProcessingRequest(false);
              if (res.data.status == 200) {
                setSweetAlert({
                  show: true,
                  title: "Congratulations!",
                  body:
                    "Thank you for your payment with txn Id: " +
                    txnId +
                    ". Please save it for future reference. You are now directly eligible for 10% Discount on Course Fee by registering for this Scholarship Test. ",
                });
              }
            })
            .catch((err) => {
              setIsProcessingRequest(false);
              setSweetAlert({
                show: true,
                title: "Failed",
                body:
                  "Transaction Failed, if money got debited from your account will get refunded in three working days.",
              });
            });
        }
      },
      // prefill: {
      //   name: "Pradeep",
      //   email: "rpradeepkbi007@gmail.com",
      // },
      notes: {
        address: "Hello World",
      },
      theme: {
        color: "#F37254",
      },
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  const fullNameValidateHandler = (fName) => {
    setCheckoutFields((prevState) => ({ ...prevState, fullName: fName }));
    if (fName.trim() === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        fullNameError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        fullNameError: false,
      }));
      return true;
    }
  };

  const qualificationValidateHandler = (Qualification) => {
    setCheckoutFields((prevState) => ({
      ...prevState,
      qualification: Qualification,
    }));
    if (Qualification.trim() === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        qualificationError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        qualificationError: false,
      }));
      return true;
    }
  };

  const mobileNumberValidateHandler = (num) => {
    setCheckoutFields((prevState) => ({ ...prevState, mobileNumber: num }));
    if ((num !== "" && !/([9876]{1})(\d{9})$/.test(num)) || num === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        mobileNumberError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        mobileNumberError: false,
      }));
      return true;
    }
  };

  const emailValidateHandler = (Email) => {
    setCheckoutFields((prevState) => ({ ...prevState, email: Email }));
    if (
      Email === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)
    ) {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        emailError: false,
      }));
      return true;
    }
  };

  const courseValidateHandler = (Course) => {
    setCheckoutFields((prevState) => ({ ...prevState, course: Course }));
    if (Course.trim() === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        courseError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        courseError: false,
      }));
      return true;
    }
  };

  const insituteValidateHandler = (Insitute) => {
    setCheckoutFields((prevState) => ({
      ...prevState,
      insituteName: Insitute,
    }));
    if (Insitute.trim() === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        insituteNameError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        insituteNameError: false,
      }));
      return true;
    }
  };

  const amountHandler = (Amount) => {
    setCheckoutFields((prevState) => ({ ...prevState, amount: Amount }));
  };

  return (
    // <div className="adjust-top">
    <div
      class="page-content"
      style={{
        backgroundImage: "url('images/bk1.jpg')",
        backgroundRepeat: "repeat, repeat",
      }}
    >
      <div class="form-v4-content">
        <div class="form-left">
          <h2>Talent Evaluation</h2>
          {/* <p class="text-1">
            Hola , To create awareness in the student community about the
            Current Affairs and General knowledge and prepare them for
            competitive exams in the near future We are bringing out India’s 1st
            Digitally enabled Talent evaluation screening test carefully curated
            by experienced faculty at affordable price. GR Study Circle Has
            experience of training over 4000 students in various competitive
            exams.
          </p> */}
          <p class="text-1">
            Hola , We are bringing out India’s 1st Digitally enabled Talent
            evaluation screening test carefully curated by experienced faculty
            at affordable price. GR Study Circle Has experience of training over
            4000 students in various competitive exams.
          </p>
          <p class="text-2">
            <span> We conduct screening test for::</span>
            <ul style={{ listStyle: "disc" }}>
              {/* <li>8th & 9th Class Students</li>
              <li>10th Class students</li>
              <li>Intermediate students</li>
              <li>Undergraduate (degree) students</li> */}
              {/* <li>Civil Services Aspirants general (Degree pass)</li>
              <li>Civil Services Aspirants (NCERT only)</li>
              <li>Civil Services Aspirants Prelims (G.S. + Current Affairs)</li> */}
              <li>Civil Services Aspirants</li>
            </ul>
          </p>

          <p class="text-2">
            <span> SYLLABUS::</span>
            <ul style={{ listStyle: "disc" }}>
              <li>NCERT - VI to XII Standard Books</li>
              <li>Question Paper - MCQ Type, 100 Questions 100 Marks</li>
              <li>ONLINE Mode</li>
            </ul>
          </p>

          <p class="text-2">
            <span> FEE CONCESSION::</span>
            <ul style={{ listStyle: "disc" }}>
              <li>50 To 59 Marks - 25% Discount </li>
              <li>60 To 74 Marks - 50% Discount </li>
              <li>75 Marks and Above - 75% Discount</li>
            </ul>
          </p>
          {/* <p>
            Note: Syllabus for Tests 1 to 5 will be their class( and below)
            Social Studies books + GK based.
          </p> */}
          <p>
            Note: On Registration you will be directly eligible for 10% Discount
            on Course Fee
          </p>
        </div>
        {/* <form class="form-detail" action="#" method="post" id="myform"> */}
        <form
          class="form-detail"
          action=""
          onSubmit={(event) => checkoutValidateHandlerWithoutPay(event)}
          // onSubmit={(event) => checkoutValidateHandler(event)}
        >
          <h2>SET A TEST</h2>
          <div class="form-group">
            <div class="form-row form-row-1">
              <label for="fullName">Full Name</label>
              <input
                type="text"
                class="form-control"
                id="fullName"
                aria-describedby="emailHelp"
                placeholder="John Doe"
                className={
                  checkoutFieldsError.fullNameError &&
                  checkoutFields.fullName !== ""
                    ? "inputFieldError input-text"
                    : !checkoutFieldsError.fullNameError &&
                      checkoutFields.fullName !== ""
                    ? "inputFieldSuccess input-text"
                    : "input-text"
                }
                value={checkoutFields.fullName}
                onChange={(event) =>
                  fullNameValidateHandler(event.target.value)
                }
              />
            </div>
            <div class="form-row form-row-1">
              <label for="mobileNumber">Phone</label>
              <input
                type="mobile"
                class="form-control"
                id="mobileNumber"
                placeholder="9999999999"
                className={
                  checkoutFieldsError.mobileNumberError &&
                  checkoutFields.mobileNumber !== ""
                    ? "inputFieldError input-text"
                    : !checkoutFieldsError.mobileNumberError &&
                      checkoutFields.mobileNumber !== ""
                    ? "inputFieldSuccess input-text"
                    : "input-text"
                }
                value={checkoutFields.mobileNumber}
                onChange={(event) =>
                  mobileNumberValidateHandler(event.target.value)
                }
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-row form-row-1">
              <label for="Email">Email address</label>
              <input
                type="email"
                class="form-control"
                id="Email"
                aria-describedby="emailHelp"
                placeholder="john@youremail.com"
                className={
                  checkoutFieldsError.emailError && checkoutFields.email !== ""
                    ? "inputFieldError input-text"
                    : !checkoutFieldsError.emailError &&
                      checkoutFields.email !== ""
                    ? "inputFieldSuccess input-text"
                    : "input-text"
                }
                value={checkoutFields.email}
                onChange={(event) => emailValidateHandler(event.target.value)}
              />
            </div>
            <div class="form-row form-row-1">
              <label for="course">Qualification</label>
              <input
                type="text"
                class="form-control"
                id="course"
                placeholder="UG/PG"
                className={
                  checkoutFieldsError.qualificationError &&
                  checkoutFields.qualification !== ""
                    ? "inputFieldError input-text"
                    : !checkoutFieldsError.qualificationError &&
                      checkoutFields.qualification !== ""
                    ? "inputFieldSuccess input-text"
                    : "input-text"
                }
                value={checkoutFields.qualification}
                onChange={(event) =>
                  qualificationValidateHandler(event.target.value)
                }
              />
            </div>
          </div>
          <div class="form-group">
            <div
              class="form-row"
              style={{
                padding: "0px 8px 0px 8px",
              }}
            >
              <label for="course">Screening Test</label>
              <select
                class="form-control form-control-teacher "
                id="Type"
                style={{
                  marginBottom: "27px",
                }}
                // className={
                //   checkoutFieldsError.courseError &&
                //   checkoutFields.course !== ""
                //     ? "inputFieldError input-text"
                //     : !checkoutFieldsError.courseError &&
                //       checkoutFields.course !== ""
                //     ? "inputFieldSuccess input-text"
                //     : "input-text"
                // }
                // value={addBlogFields.course}
                onChange={(event) => courseValidateHandler(event.target.value)}
              >
                {/* <option value="Below 8th class">Below 8th class</option>
                <option value="8th & 9th Class Students">
                  8th & 9th Class Students
                </option>
                <option value="10th Class students">10th Class students</option>
                <option value="Intermediate students">
                  Intermediate students
                </option>
                <option value="Undergraduate (degree) students">
                  Undergraduate (degree) students
                </option> */}
                {/* <option value="Civil Services Aspirants general (Degree pass)">
                  Civil Services Aspirants general (Degree pass)
                </option>
                <option value="Civil Services Aspirants (NCERT only)">
                  Civil Services Aspirants (NCERT only)
                </option>
                <option value="Civil Services Aspirants Prelims (G.S. + Current Affairs)">
                  Civil Services Aspirants Prelims (G.S. + Current Affairs)
                </option> */}
                <option value="Civil Services Aspirants">
                  Civil Services Aspirants
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="form-row form-row-1">
              {/* <label for="Email">School/College Name</label> */}
              <label for="Email">Place</label>

              <input
                type="text"
                class="form-control"
                id="InsituteName"
                placeholder="Hyderabad"
                className={
                  checkoutFieldsError.insituteNameError &&
                  checkoutFields.insituteName !== ""
                    ? "inputFieldError input-text"
                    : !checkoutFieldsError.insituteNameError &&
                      checkoutFields.insituteName !== ""
                    ? "inputFieldSuccess input-text"
                    : "input-text"
                }
                value={checkoutFields.insituteName}
                onChange={(event) =>
                  insituteValidateHandler(event.target.value)
                }
              />
            </div>

            <div class="form-row form-row-1">
              <label for="date">Date of Exam</label>
              <input
                type="text"
                value="12-June-2022"
                class="form-control"
                disabled
                id="date"
                className={
                  checkoutFieldsError.courseError &&
                  checkoutFields.course !== ""
                    ? "inputFieldError input-text"
                    : !checkoutFieldsError.courseError &&
                      checkoutFields.course !== ""
                    ? "inputFieldSuccess input-text"
                    : "input-text"
                }
                onChange={(event) => courseValidateHandler(event.target.value)}
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <label for="Amount">Amount</label>
              <input
                type="text"
                class="form-control"
                id="Amount"
                disabled
                placeholder="100"
                className="input-text"
                value="Pay 0(Waived off for first 100 registrations)"
                onChange={(event) => amountHandler(event.target.value)}
              />
            </div>
            {/* <div class="form-row form-row-1">
              <label for="Amount">Amount</label>
              <input
                type="number"
                class="form-control"
                id="Amount"
                disabled
                placeholder="100"
                className="input-text"
                value={checkoutFields.amount}
                onChange={(event) => amountHandler(event.target.value)}
              />
            </div> */}
          </div>
          <div class="form-checkbox">
            <label class="container">
              <p>
                I agree to the{" "}
                <a href="#" class="text">
                  Terms and Conditions
                </a>
              </p>
              <input type="checkbox" name="checkbox" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form-row-last">
            <input
              type="submit"
              value={isProcessingRequest ? "Processing..." : "Book Now"}
              class="register"
              disabled={isProcessingRequest}
              id="loginBtn"
            />
          </div>
        </form>
      </div>
      {/* </div> */}

      <CustomAlert
        show={sweetAlert.show}
        title={sweetAlert.title}
        body={sweetAlert.body}
        close={() =>
          setSweetAlert((prevState) => ({ ...prevState, show: false }))
        }
      />
    </div>
  );
};

export default Talent;
