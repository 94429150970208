import React, { useState } from "react";
import Popup from "../Popup/Popup";

import "../login/login.css";
import Axios from "../../api";
import CustomAlert from "../sweet-alert/sweetAlert";

const RequestDemo = (props) => {
  const [requestDemoFields, setRequestDemoFields] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    courses: "",
  });

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const [requestDemoFieldsError, setRequestDemoFieldsError] = useState({
    fullNameError: false,
    emailError: false,
    phoneNumberError: false,
    coursesError: false,
  });

  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const validateFullNameHandler = (value) => {
    setRequestDemoFields((prevState) => ({ ...prevState, fullName: value }));
    if (value === "") {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        fullNameError: true,
      }));
      return false;
    } else {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        fullNameError: false,
      }));
      return true;
    }
  };

  const validateEmailhandler = (data) => {
    setRequestDemoFields((prevState) => ({ ...prevState, email: data }));
    if (
      data === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)
    ) {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      return false;
    } else {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        emailError: false,
      }));
      return true;
    }
  };

  const validatePhoneNumberHandler = (phn) => {
    setRequestDemoFields((prevState) => ({ ...prevState, phoneNumber: phn }));
    if ((phn !== "" && !/([9876]{1})(\d{9})$/.test(phn)) || phn === "") {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        phoneNumberError: true,
      }));
      return false;
    } else {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        phoneNumberError: false,
      }));
      return true;
    }
  };

  const validateCourseHandler = (course) => {
    setRequestDemoFields((prevState) => ({ ...prevState, courses: course }));
    if (course === "") {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        coursesError: true,
      }));
      return false;
    } else {
      setRequestDemoFieldsError((prevState) => ({
        ...prevState,
        coursesError: false,
      }));
      return true;
    }
  };

  const validateAndRequestDemo = (event) => {
    event.preventDefault();
    setIsProcessingRequest(true);
    if (
      validateFullNameHandler(requestDemoFields.fullName) &&
      validateEmailhandler(requestDemoFields.email) &&
      validatePhoneNumberHandler(requestDemoFields.phoneNumber) &&
      validateCourseHandler(requestDemoFields.courses)
    ) {
      const requestDemoObject = {
        full_name: requestDemoFields.fullName,
        emailId: requestDemoFields.email,
        mobileNo: requestDemoFields.phoneNumber,
        course: requestDemoFields.courses,
      };
      console.log(
        "All validations are passed. please wait while submitting your request.",
        requestDemoObject
      );
      Axios.post("v1/demos/", requestDemoObject)
        .then((res) => {
          props.closePopup();
          setIsProcessingRequest(false);
          setSweetAlert({
            show: true,
            title: "Success",
            body:
              "Thank you for your interest in demo.  Please check your email for further details , we will contact you shortly.",
          });
        })
        .catch((err) => {
          setIsProcessingRequest(false);
          setSweetAlert({
            show: true,
            title: "Failed",
            body: "Someting went wrong, please try again later.",
          });
        });
    } else {
      alert(
        "All the fields are maindatory, please check all the fields and try again!"
      );
      setIsProcessingRequest(false);
    }
  };
  return (
    <div className="request-demo-popup-wrapper">
      <Popup
        visible={props.visible}
        height="100%"
        width="100%"
        effect="fadeInDown"
      >
        <div className="request-demo-form-wrapper">
          <div className="close-button" onClick={props.closePopup}>
            <span>&times;</span>
          </div>
          <div className="image-holder">
            <h2>DEMO</h2>
          </div>
          <div className="form-wrapper">
            <form onSubmit={(event) => validateAndRequestDemo(event)}>
              <div className="fullName-controller input-field-wrapper">
                <input
                  type="text"
                  id="reqFullName"
                  className={
                    requestDemoFieldsError.fullNameError &&
                    requestDemoFields.fullName !== ""
                      ? "inputFieldError form-control"
                      : !requestDemoFieldsError.fullNameError &&
                        requestDemoFields.fullName !== ""
                      ? "inputFieldSuccess form-control"
                      : "form-control"
                  }
                  value={requestDemoFields.fullName}
                  onChange={(event) =>
                    validateFullNameHandler(event.target.value)
                  }
                />
                <span className="lable move-top">Full Name</span>
              </div>
              <div className="email-controller input-field-wrapper">
                <input
                  type="email"
                  id="reqEmail"
                  className={
                    requestDemoFieldsError.emailError &&
                    requestDemoFields.email !== ""
                      ? "inputFieldError form-control"
                      : !requestDemoFieldsError.emailError &&
                        requestDemoFields.email !== ""
                      ? "inputFieldSuccess form-control"
                      : "form-control"
                  }
                  value={requestDemoFields.email}
                  onChange={(event) => validateEmailhandler(event.target.value)}
                />
                <span className="lable move-top">Email</span>
              </div>
              <div className="phone-number-controller input-field-wrapper">
                <input
                  type="phone"
                  id="reqMobileNumber"
                  className={
                    requestDemoFieldsError.phoneNumberError &&
                    requestDemoFields.phoneNumber !== ""
                      ? "inputFieldError form-control"
                      : !requestDemoFieldsError.phoneNumberError &&
                        requestDemoFields.phoneNumber !== ""
                      ? "inputFieldSuccess form-control"
                      : "form-control"
                  }
                  value={requestDemoFields.phoneNumber}
                  onChange={(event) =>
                    validatePhoneNumberHandler(event.target.value)
                  }
                />
                <span className="lable move-top">Phone Number</span>
              </div>
              <div className="course-controller input-field-wrapper">
                <input
                  type="text"
                  id="reqCourses"
                  className={
                    requestDemoFieldsError.coursesError &&
                    requestDemoFields.courses !== ""
                      ? "inputFieldError form-control"
                      : !requestDemoFieldsError.coursesError &&
                        requestDemoFields.courses !== ""
                      ? "inputFieldSuccess form-control"
                      : "form-control"
                  }
                  value={requestDemoFields.courses}
                  onChange={(event) =>
                    validateCourseHandler(event.target.value)
                  }
                />
                <span className="lable move-top">Courses</span>
              </div>
              <div>
                <input
                  style={{
                    float: "right",
                    position: "relative",
                    right: "27px",
                    bottom: "12px",
                  }}
                  disabled={isProcessingRequest}
                  type="submit"
                  className="btn btn-primary"
                  value={isProcessingRequest ? "Processing..." : "Request Demo"}
                />
              </div>
            </form>
          </div>
        </div>
      </Popup>
      <CustomAlert
        show={sweetAlert.show}
        title={sweetAlert.title}
        body={sweetAlert.body}
        close={() =>
          setSweetAlert((prevState) => ({ ...prevState, show: false }))
        }
      />
    </div>
  );
};

export default RequestDemo;
