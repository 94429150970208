import * as actionTypes from "./actionTypes";

const initialState = {
    key: ""
}

const reducer = (state = initialState, action) => {
    return state;
}

export default reducer;