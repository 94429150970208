import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Api from '../../api';
import './Auth.css';



const Auth = (prop) => {
    useEffect(() => {
        openPopup(prop);
    });

    const [visiblePopup, setVisiblePopup] = useState(false);
    const [visibleRefferer, setVisibleRefferer] = useState("login");
    const [snackBarProps, setSnackBarProps] = useState({
        visible: false,
        message: ""
    });


    const [showSweetAlert, setShowSweetAlert] = useState({
        show: false,
        title: "",
        body: ""
    });
    const [loginFields, setLoginFields] = useState({
        userName: "",
        password: ""
    });

    const [loginFieldsError, setLoginFieldsError] = useState({
        userNameError: false,
        passwordError: false
    });

    const [registerFields, setRegisterFields] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        confirmPassword: "",
        mobileNuber: "",
        aggreeTC: false
    });

    const [registerFieldsError, setRegisterFieldsError] = useState({
        firstNameError: false,
        lastNameError: false,
        userNameError: false,
        passwordError: false,
        confirmPasswordError: false,
        mobileNumberError: false
    });

    const [passwordVallidators, setPasswordVallidators] = useState({
        capitalLetter: false,
        smallLetter: false,
        number: false,
        specialCharacter: false
    });

    const loginValidateHandler = (event) => {
        event.preventDefault();
        if (validateUserNameHandler(loginFields.userName, "login") && validatePasswordHandler(loginFields.password, "login")) {
            const loginObject = {
                "emailId": loginFields.userName,
                "password": loginFields.password
            }

            Api.post("v1/users/authenticate", loginObject).then(res => {
                setSnackBarProps({
                    visible: true,
                    message: "Login Success!"
                }).catch(err => {
                    alert("Login failed please try again.");
                })
                console.log("got data from json placeholder: ", res);
            });
        } else {
            console.log(loginFields);
        }
        return false;
    }



    const registerValidateHandler = (event) => {
        event.preventDefault();
        // console.log(fullName + " " + rUserName + " " + rPassword + " " + mNumber);
        if (firstNameValidateHandler(registerFields.firstName) && lastNameValidateHandler(registerFields.lastName) && validateUserNameHandler(registerFields.userName, "register") && validatePasswordHandler(registerFields.password, "register") && confirmPasswordValidateHandler(registerFields.confirmPassword) && mobileNumberValidateHandler(registerFields.mobileNuber) && registerFields.aggreeTC) {
            const registerObject = {
                "first_name": registerFields.firstName,
                "last_name": registerFields.lastName,
                "mobileNo": registerFields.mobileNuber,
                "emailId": registerFields.userName,
                "password": registerFields.password,
                "role": "user",
                "agreeTC": registerFields.aggreeTC ? 1 : 0
            };
            console.log("register object is ", registerObject);
            Api.post("v1/users/user", registerObject).then(res => {
                setSnackBarProps({
                    visible: true,
                    message: "User registered successfully!"
                });
                console.log("User created successfully, proceed to login.", res)
            }).catch(err => {
                alert("Registeration failed.");
            });
        } else {
            console.log("some thing went wrong please try again later.", registerFields);
        }
        return false;

    }

    const validateUserNameHandler = (data, mode) => {
        (mode === "login") ? setLoginFields(prevState => ({ ...prevState, userName: data })) : setRegisterFields(prevState => ({ ...prevState, userName: data }));
        if (data === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(data)) {
            (mode === "login") ? setLoginFieldsError(prevState => ({
                ...prevState, userNameError: true
            })) : setRegisterFieldsError(prevState => ({ ...prevState, userNameError: true }));
            return false;
        }
        else {
            (mode === "login") ? setLoginFieldsError(prevState => ({
                ...prevState, userNameError: false
            })) : setRegisterFieldsError(prevState => ({ ...prevState, userNameError: false }));
            return true;
        }

    }

    const validatePasswordHandler = (data, mode) => {
        ((/^(?=.*[a-z])/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, smallLetter: true })) : setPasswordVallidators(prevState => ({ ...prevState, smallLetter: false }));
        ((/^(?=.*[A-Z])/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, capitalLetter: true })) : setPasswordVallidators(prevState => ({ ...prevState, capitalLetter: false }));
        ((/^(?=.*[0-9])/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, number: true })) : setPasswordVallidators(prevState => ({ ...prevState, number: false }));
        ((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, specialCharacter: true })) : setPasswordVallidators(prevState => ({ ...prevState, specialCharacter: false }));

        (mode === "login") ? setLoginFields(prevState => ({ ...prevState, password: data })) : setRegisterFields(prevState => ({ ...prevState, password: data }));
        if (data === "" || !(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/).test(data)) {
            (mode === "login") ? setLoginFieldsError(prevState => ({ ...prevState, passwordError: true })) : setRegisterFieldsError(prevState => ({ ...prevState, passwordError: true }));
            return false;
        } else {
            (mode === "login") ? setLoginFieldsError(prevState => ({ ...prevState, passwordError: false })) : setRegisterFieldsError(prevState => ({ ...prevState, passwordError: false }));
            return true;
        }
    }

    const firstNameValidateHandler = (fName) => {
        setRegisterFields(prevState => ({ ...prevState, firstName: fName }));
        if (fName.trim() === "") {
            setRegisterFieldsError(prevState => ({ ...prevState, firstNameError: true }));
            return false;
        }
        else {
            setRegisterFieldsError(prevState => ({ ...prevState, firstNameError: false }));
            return true;
        }
    }

    const lastNameValidateHandler = (fName) => {
        setRegisterFields(prevState => ({ ...prevState, lastName: fName }));
        if (fName.trim() === "") {
            setRegisterFieldsError(prevState => ({ ...prevState, lastNameError: true }));
            return false;
        }
        else {
            setRegisterFieldsError(prevState => ({ ...prevState, lastNameError: false }));
            return true;
        }
    }

    const confirmPasswordValidateHandler = (confirmpwd) => {
        setRegisterFields(prevState => ({ ...prevState, confirmPassword: confirmpwd }));
        if (confirmpwd !== registerFields.password) {
            setRegisterFieldsError(prevState => ({ ...prevState, confirmPasswordError: true }));
            return false;
        } else {
            setRegisterFieldsError(prevState => ({ ...prevState, confirmPasswordError: false }));
            return true;
        }
    }

    const mobileNumberValidateHandler = (num) => {
        setRegisterFields(prevState => ({ ...prevState, mobileNuber: num }));
        if ((num !== "" && !(/([9876]{1})(\d{9})$/).test(num)) || (num === "")) {
            setRegisterFieldsError(prevState => ({ ...prevState, mobileNumberError: true }));
            return false;
        } else {
            setRegisterFieldsError(prevState => ({ ...prevState, mobileNumberError: false }));
            return true;
        }
    }

    const switchpage = (event) => {
        event.preventDefault();
        document.getElementById("login").classList.toggle("hide");
        document.getElementById("register").classList.toggle("hide");
    }

    const openPopup = (prop) => {
        setVisiblePopup(prop.visible);
        setVisibleRefferer(prop.referrer);
    }

    return (
        <div className="main-wrapper">
            <div className="wrapper-div">
                <div className="login-wrapper">
                    <div className="welcome-wrapper">
                        <h2>Welcome</h2>
                        <img src="./images/apple-touch-icon.png" alt="login-logo" className="my-5"/>
                    </div>
                    <form action="" onSubmit={(event) => loginValidateHandler(event)}>
                        <div className="username-div input-field-wrapper">
                            <input type="email" id="userName" className={(loginFieldsError.userNameError && loginFields.userName !== "px-4") ? "inputFieldError form-control px-4" : (!loginFieldsError.userNameError && loginFields.userName !== "") ? "inputFieldSuccess form-control" : "form-control"} value={loginFields.userName} onChange={(event) => validateUserNameHandler(event.target.value, "login")} />
                            <span className="lable move-top">Email</span>
                        </div>
                        <div className="password-div input-field-wrapper">
                            <input type="password" id="pwd" value={loginFields.password} className={(loginFieldsError.passwordError && loginFields.password !== "") ? "inputFieldError form-control" : (!loginFieldsError.passwordError && loginFields.password !== "") ? "inputFieldSuccess form-control" : "form-control"} onChange={(event) => validatePasswordHandler(event.target.value, "login")} />
                            <span className="lable move-top">Password</span>
                        </div>
                        <div className="submit-wrapper">
                            <label><input type="checkbox" id="remember" /> &nbsp; <span>Remember me</span></label>
                            <input type="submit" value="Login" className="btn btn-primary login-btn" disalbed="true" id="loginBtn" /><br />
                        </div>
                        <div className="become-member"><Link to="#" onClick={switchpage}>Become a member.</Link></div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Auth;