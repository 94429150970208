import React, { useState } from "react";
import { Link } from "react-router-dom";

const SubscriptionComponent = () => {
  const [subScriptionPlan, setSubscriptionPlan] = useState("monthly");
  const updateSubScription = (plan) => {
    setSubscriptionPlan(plan);
  };
  return (
    <div>
      <div id="plan" className="section lb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Choose Your Program</h3>
          </div>

          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="message-box">
                <ul className="nav nav-pills nav-stacked" id="myTabs">
                  <li>
                    <Link
                      className="active"
                      to="#tab1"
                      data-toggle="pill"
                      onClick={() => updateSubScription("monthly")}
                    >
                      UPSC Mains
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#tab2"
                      data-toggle="pill"
                      onClick={() => updateSubScription("yearly")}
                    >
                      UPSC Prelims
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr className="invis" />

          <div className="row">
            <div className="col-md-12">
              <div className="tab-content">
                <div
                  className={
                    subScriptionPlan === "monthly"
                      ? "tab-pane active fade show"
                      : "tab-pane fade"
                  }
                  id="tab1"
                >
                  <div className="row text-center">
                    <div className="col-md-4">
                      <div className="pricing-table pricing-table-highlighted">
                        <div className="pricing-table-header grd1">
                          {/* <h2>$45</h2> */}
                          <h3>UPSC-CSE Prelims cum Mains</h3>
                        </div>
                        <div className="pricing-table-space"></div>
                        <div className="pricing-table-features">
                          <p>
                            <i className="fa fa-calendar"></i>{" "}
                            <strong>9 Months</strong>
                          </p>
                          <p>
                            <i className="fa fa-inr"></i>{" "}
                            <strong>1,25,000/-</strong>
                          </p>
                          <p>
                            <i className="fa fa-database"></i>{" "}
                            <strong>Practice tests</strong>
                          </p>
                          <p>
                            <i className="fa fa-user"></i>{" "}
                            <strong>Experienced faculty</strong>{" "}
                          </p>
                          <p>
                            <i className="fa fa-envelope"></i>{" "}
                            <strong>Unmatched Support</strong>
                          </p>
                        </div>
                        <div className="pricing-table-sign-up">
                          <Link
                            to="/paycheckout"
                            className="hover-btn-new orange"
                          >
                            <span>Apply Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pricing-table pricing-table-highlighted">
                        <div className="pricing-table-header grd1">
                          {/* <h2>$59</h2> */}
                          <h3>UPSC-CSE Mains Crash Course</h3>
                        </div>
                        <div className="pricing-table-space"></div>
                        <div className="pricing-table-features">
                          <p>
                            <i className="fa fa-calendar"></i>{" "}
                            <strong>3 Months</strong>
                          </p>
                          <p>
                            <i className="fa fa-inr"></i>{" "}
                            <strong>50,000/-</strong>
                          </p>
                          <p>
                            <i className="fa fa-database"></i>{" "}
                            <strong>Practice tests</strong>
                          </p>
                          <p>
                            <i className="fa fa-user"></i>{" "}
                            <strong>Experienced faculty</strong>{" "}
                          </p>
                          <p>
                            <i className="fa fa-envelope"></i>{" "}
                            <strong>Unmatched Support</strong>
                          </p>
                        </div>
                        <div className="pricing-table-sign-up">
                          <Link
                            to="/paycheckout"
                            className="hover-btn-new orange"
                          >
                            <span>Apply Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pricing-table pricing-table-highlighted">
                        <div className="pricing-table-header grd1">
                          {/* <h2>$500</h2> */}
                          <h3>UPSC-CSE Mains Optional Papers</h3>
                        </div>
                        <div className="pricing-table-space"></div>
                        <div className="pricing-table-features">
                          <p>
                            <i className="fa fa-calendar"></i>{" "}
                            <strong>6 to 8 Months</strong>
                          </p>
                          <p>
                            <i className="fa fa-inr"></i>{" "}
                            <strong>60,000/-</strong>
                          </p>
                          <p>
                            <i className="fa fa-database"></i>{" "}
                            <strong>Practice tests</strong>
                          </p>
                          <p>
                            <i className="fa fa-user"></i>{" "}
                            <strong>Experienced faculty</strong>{" "}
                          </p>
                          <p>
                            <i className="fa fa-envelope"></i>{" "}
                            <strong>Unmatched Support</strong>
                          </p>
                        </div>
                        <div className="pricing-table-sign-up">
                          <Link
                            to="/paycheckout"
                            className="hover-btn-new orange"
                          >
                            <span>Apply Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    subScriptionPlan === "yearly"
                      ? "tab-pane active fade show"
                      : "tab-pane fade"
                  }
                  id="tab2"
                >
                  <div className="row text-center">
                    <div className="col-md-4">
                      <div className="pricing-table pricing-table-highlighted">
                        <div className="pricing-table-header grd1">
                          {/* <h2>$477</h2> */}
                          <h3> UPSC-CSE Prelims Crash Course</h3>
                        </div>
                        <div className="pricing-table-space"></div>
                        <div className="pricing-table-features">
                          <p>
                            <i className="fa fa-calendar"></i>{" "}
                            <strong>3 Months</strong>
                          </p>
                          <p>
                            <i className="fa fa-inr"></i>{" "}
                            <strong>30,000/-</strong>
                          </p>
                          <p>
                            <i className="fa fa-database"></i>{" "}
                            <strong>Practice Tests</strong>
                          </p>
                          <p>
                            <i className="fa fa-user"></i>{" "}
                            <strong>Experienced faculty</strong>{" "}
                          </p>
                          <p>
                            <i className="fa fa-envelope"></i>{" "}
                            <strong>Unmatched Support</strong>
                          </p>
                        </div>
                        <div className="pricing-table-sign-up">
                          <Link
                            to="/paycheckout"
                            className="hover-btn-new orange"
                          >
                            <span>Apply Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pricing-table pricing-table-highlighted">
                        <div className="pricing-table-header grd1">
                          {/* <h2>$485</h2> */}
                          <h3> UPSC-CSE Prelims - CSAT Only</h3>
                        </div>
                        <div className="pricing-table-space"></div>
                        <div className="pricing-table-features">
                          <p>
                            <i className="fa fa-calendar"></i>{" "}
                            <strong>3 Months</strong>
                          </p>
                          <p>
                            <i className="fa fa-inr"></i>{" "}
                            <strong>15,000/-</strong>
                          </p>
                          <p>
                            <i className="fa fa-database"></i>{" "}
                            <strong>Practice Tests</strong>
                          </p>
                          <p>
                            <i className="fa fa-user"></i>{" "}
                            <strong>Experienced faculty</strong>{" "}
                          </p>
                          <p>
                            <i className="fa fa-envelope"></i>{" "}
                            <strong>Unmatched Support</strong>
                          </p>
                        </div>
                        <div className="pricing-table-sign-up">
                          <Link
                            to="/paycheckout"
                            className="hover-btn-new orange"
                          >
                            <span>Apply Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="pricing-table pricing-table-highlighted">
                        <div className="pricing-table-header grd1">
                          {/* <h2>$85</h2> */}
                          <h3>Newspaper Analysis</h3>
                        </div>
                        <div className="pricing-table-space"></div>
                        <div className="pricing-table-features">
                          <p>
                            <i className="fa fa-calendar"></i>{" "}
                            <strong>12 Months</strong>
                          </p>
                          <p>
                            <i className="fa fa-inr"></i>{" "}
                            <strong>24,000/-</strong>
                          </p>
                          <p>
                            <i className="fa fa-database"></i>{" "}
                            <strong>Current Affairs Included</strong>
                          </p>
                          <p>
                            <i className="fa fa-user"></i>{" "}
                            <strong>Experienced faculty</strong>{" "}
                          </p>
                          <p>
                            <i className="fa fa-envelope"></i>{" "}
                            <strong>Unmatched Support</strong>
                          </p>
                        </div>
                        <div className="pricing-table-sign-up">
                          <Link
                            to="/paycheckout"
                            className="hover-btn-new orange"
                          >
                            <span>Apply Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
