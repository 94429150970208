import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Axios from "axios";
import Api from "../../api";

import "./CoursesGridOne.css";
import InnerLoder from "../Loader-inner/InnerLoader";
import PageNation from "../Page-Nation/PageNation";
import RequestDemo from "../RequestDemo/RequestDemo";

const CoursesGridOne = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [coursesBackUp, setCoursesBackUp] = useState([{}]);
  const [courses, setCourses] = useState([{}]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);
  const [pageNationArray, setPageNationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    Api.get("v1/Courses/")
      .then((res) => {
        console.log("res: all courses ", res);
        setCoursesBackUp(res.data.result);
        preFetchImages(res.data.result);
        loadCoursesToShow(res.data.result, currentPage);
        let pageCountArray = [];
        for (let i = 0, j = 1; i < res.data.result.length / 12; i++) {
          pageCountArray.push(j);
          ++j;
        }
        setPageNationArray(pageCountArray);
        setShowLoader(false);
      })
      .catch((err) => console.log("Error while retriving courses from data"));
    window.scrollTo(0, 0);
  }, []);

  const loadCoursesToShow = (dataArray, page) => {
    let dataToShow = [];
    for (let i = (page - 1) * 12; i < page * 12; i++) {
      if (dataArray[i]) dataToShow.push(dataArray[i]);
    }
    console.log("Data getting rendered: ", dataToShow);
    setCourses(dataToShow);
  };

  const preFetchImages = (coursesList) => {
    let imagesArray = coursesList.map((course) => course.coursePicture);
    caheImage(removeDuplicatesInArray(imagesArray), 0);
  };

  const caheImage = (imagesArray, ind) => {
    if (ind < imagesArray.length) {
      imgPromise(imagesArray[ind])
        .then(() => {
          caheImage(imagesArray, ++ind);
        })
        .catch((err) => {
          caheImage(imagesArray, ind);
        });
    } else {
      console.log("All the images are loaded, stopping the spinner");
      setTimeout(() => {
        setIsAllImagesLoaded(true);
      }, 3000);
    }
  };

  const imgPromise = (src) => {
    console.log(src);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = "https://cdn.griasacademy.com" + src;
      img.onload = resolve();
      img.onerror = reject();
    });
  };

  const removeDuplicatesInArray = (imgArray) => {
    let filteredImagesArray = imgArray.filter(
      (img, index, imgArray) => imgArray.indexOf(img) === index
    );
    return filteredImagesArray;
  };

  const filterCourses = (event) => {
    let filteredCourses = [];
    if (event.target.value === "") {
      setCourses(coursesBackUp);
    } else {
      coursesBackUp.map((course) => {
        if (course.category === event.target.value)
          filteredCourses.push(course);
      });
      setCourses(filteredCourses);
    }
    setIsFiltered(true);
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className="adjust-top">
      <div className="all-title-box">
        <div className="container text-center">
          <h1>Courses(Offline/Online)</h1>
        </div>
        <div
          className="big-tagline"
          style={{
            textAlign: "right",
            marginRight: "3em",
            marginTop: "3em",
          }}
        >
          <Link
            to="/course"
            onClick={() => setShowPopUp(true)}
            className="hover-btn-new"
          >
            <span>Request Demo</span>
          </Link>
        </div>
      </div>
      {!isAllImagesLoaded ? (
        <InnerLoder />
      ) : (
        <div id="overviews" className="section wb">
          <div className="container">
            <hr className="invis" />
            <div className="quick-filters">
              <span className="float-right">
                <select
                  className="form-control"
                  onChange={(event) => filterCourses(event)}
                >
                  <option default selected hidden>
                    Quick-Filters
                  </option>
                  <option value="">All</option>
                  <option value="Civils">Civils</option>
                  <option value="Civils-SubjectWise">
                    Civils-SubjectWise-Modules
                  </option>
                  <option value="Civils-Optionals">Civils-Optionals</option>
                </select>
              </span>
            </div>
            <PageNation>
              <div className="row">
                {courses.map((course, index) => {
                  let name = course.name;
                  if (name) {
                    name = course.name.replace(/,?\s+/g, "-");
                    console.log("nameeeee", name);
                  }
                  return (
                    <div className="col-lg-3 col-md-6 col-12 my-3" key={index}>
                      <Link
                        to={"/course/" + course.courseId + "/" + name}
                        title=""
                      >
                        <div className="course-item">
                          <div className="image-blog">
                            <Link
                              to={"/course/" + course.courseId + "/" + name}
                              title=""
                            >
                              <img
                                src={
                                  "https://cdn.griasacademy.com" +
                                  course.coursePicture
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                          <div className="course-br">
                            {/* <div className="course-title">
                            <h2>
                              <Link
                                to={"/course/" + course.courseId + "/" + name}
                                title=""
                              >
                                {course.name}
                              </Link>
                            </h2>
                          </div> */}
                            <div className="course-desc p-wrapper">
                              <p className="add-dots">{course.description}</p>
                            </div>
                          </div>
                          <div className="course-meta-bot">
                            <ul>
                              <li>
                                <div className="course-rating">
                                  4.5
                                  <i className="fa fa-star"></i>
                                  <i className="fa fa-star"></i>
                                  <i className="fa fa-star"></i>
                                  <i className="fa fa-star"></i>
                                  <i className="fa fa-star-half"></i>
                                </div>
                              </li>
                              <li>
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>{" "}
                                {course.duration}
                              </li>
                              <li>
                                <i
                                  className="fa fa-users"
                                  aria-hidden="true"
                                ></i>{" "}
                                {course.availableSeats} &nbsp; Students
                              </li>
                              {/* <li>
                            <i className="fa fa-book" aria-hidden="true"></i> {course.books} Books
                      </li> */}
                            </ul>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </PageNation>
            {!isFiltered ? (
              <nav
                aria-label="Page navigation example"
                className="my-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ul className="pagination">
                  <li class="page-item">
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => {
                        setCurrentPage(
                          currentPage - 1 <= 1 ? 1 : currentPage - 1
                        );
                        loadCoursesToShow(
                          coursesBackUp,
                          currentPage - 1 <= 1 ? 1 : currentPage - 1
                        );
                      }}
                    >
                      Previous
                    </Link>
                  </li>
                  {pageNationArray.map((page) => (
                    <li className="page-item">
                      <Link
                        to="#"
                        className={
                          currentPage === page
                            ? "page-link active"
                            : "page-link"
                        }
                        onClick={() => {
                          setCurrentPage(page);
                          loadCoursesToShow(coursesBackUp, page);
                        }}
                      >
                        {page}
                      </Link>
                    </li>
                  ))}
                  <li class="page-item">
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => {
                        setCurrentPage(
                          currentPage + 1 >= pageNationArray.length
                            ? pageNationArray.length
                            : currentPage + 1
                        );
                        loadCoursesToShow(
                          coursesBackUp,
                          currentPage + 1 >= pageNationArray.length
                            ? pageNationArray.length
                            : currentPage + 1
                        );
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            ) : (
              ""
            )}
          </div>
          <RequestDemo
            visible={showPopUp}
            closePopup={() => setShowPopUp(false)}
          />
        </div>
      )}
    </div>
  );
};

export default CoursesGridOne;
