import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Popup from "../Popup/Popup";
import CustomAlert from "../sweet-alert/sweetAlert";

import Api from "../../api";
import "./checkout.css";
import SnackBar from "../Snackbar/Snackbar";

const PayCheckout = (prop) => {
  // useEffect(() => {
  //     openPopup(prop);
  // });

  // const [visiblePopup, setVisiblePopup] = useState(false);
  // const [visibleRefferer, setVisibleRefferer] = useState("login");

  const [snackBarProps, setSnackBarProps] = useState({
    visible: false,
    message: "",
  });

  // const [snackBarProps, setSnackBarProps] = useState({
  //   visible: false,
  //   message: "",
  // });

  // const [txnId, setTxnId] = useState("");

  const [showSweetAlert, setShowSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    body: "",
  });

  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const [checkoutFields, setCheckoutFields] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    course: "",
    paymentType: "Course",
    amount: 0,
  });

  const [transactionFields, setTransactionFields] = useState({
    razorpay_order_id: "",
    razorpay_payment_id: "",
    razorpay_signature: "",
  });

  const [orderIdFields, setOrderIdFields] = useState({
    orderId: "",
  });

  const [checkoutFieldsError, setCheckoutFieldsError] = useState({
    fullNameError: false,
    emailError: false,
    mobileNumberError: false,
    courseError: false,
    amountError: false,
  });

  const checkoutValidateHandler = (event) => {
    event.preventDefault();
    setIsProcessingRequest(true);
    // console.log(fullName + " " + rUserName + " " + rPassword + " " + mNumber);
    if (
      fullNameValidateHandler(checkoutFields.fullName) &&
      mobileNumberValidateHandler(checkoutFields.mobileNumber) &&
      emailValidateHandler(checkoutFields.email)
    ) {
      const checkoutObject = {
        fullName: checkoutFields.fullName,
        phoneNumber: checkoutFields.mobileNumber,
        email: checkoutFields.email,
        course: checkoutFields.course,
        paymentType: checkoutFields.paymentType,
        amount: checkoutFields.amount,
      };
      // console.log("check object is ", checkoutObject);
      // Api.post("v1/users/createorder", checkoutObject)
      Api.post("v1/payment/createorder", checkoutObject)

        .then((res) => {
          // setSnackBarProps({
          //   visible: true,
          //   message: "User registered successfully!",
          // });
          console.log("inside fe createorder res");
          if (res.data.result.orderId != "") {
            console.log(
              "txn id" + res.data.result.receipt + res.data.result.receipt
            );
            // setTxnId(res.data.result.receipt);
            razorPayHandler(res.data.result.orderId, res.data.result.receipt);
          } else {
            // console.log("no order id found");
            setSweetAlert({
              show: true,
              title: "Failed",
              body: "Order not created , please try again.",
            });
            setIsProcessingRequest(false);
          }
          // setOrderIdFields({
          //   orderId: res.data.result.orderId,
          // });
          // console.log("orderId", orderIdFields.orderId);
        })
        .catch((err) => {
          alert("Please try again.");
          setIsProcessingRequest(false);
        });

      // razorPayHandler(orderIdFields.orderId);

      // if (orderIdFields.orderId != "") {
      //   razorPayHandler(orderIdFields.orderId);
      // } else {
      //   console.log("No order id from backend");
      // }
    } else {
      // console.log("some thing went wrong please try again later.");
      setIsProcessingRequest(false);
    }
    return false;
  };

  const razorPayHandler = (orderId, txnId) => {
    console.log("inside razor pay handler", orderId);
    let options = {
      //key: "rzp_test_jwv3SEVlPcoh8v",

      key: "rzp_live_RIJcbtFMZHeY9q",
      amount: checkoutFields.amount, // 2000 paise = INR 20, amount in paisa
      name: "GRSC",
      description: "Course Purchase",
      order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      //   image: "/your_logo.png",
      handler: function (response) {
        // alert(response);
        // console.log("Payment response from razor pay", response);
        if (response != null) {
          // setTxnId(response.result.receipt);
          const transactionObject = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            // transaction_id: response.txnid,
          };

          Api.post("v1/payment/verifypayment", transactionObject)
            .then((res) => {
              // console.log("Transaction done.", res);
              setIsProcessingRequest(false);
              if (res.data.status == 200) {
                setSweetAlert({
                  show: true,
                  title: "Success",
                  body:
                    "Thank you for your payment with txn Id: " +
                    txnId +
                    ". Please save it for future reference of your payment.",
                });
              }
            })
            .catch((err) => {
              setIsProcessingRequest(false);
              setSweetAlert({
                show: true,
                title: "Failed",
                body:
                  "Transaction Failed, if money got debited from your account will get refunded in three working days.",
              });
            });
        }
      },
      // prefill: {
      //   name: "Pradeep",
      //   email: "rpradeepkbi007@gmail.com",
      // },
      notes: {
        address: "Hello World",
      },
      theme: {
        color: "#F37254",
      },
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  const fullNameValidateHandler = (fName) => {
    setCheckoutFields((prevState) => ({ ...prevState, fullName: fName }));
    if (fName.trim() === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        fullNameError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        fullNameError: false,
      }));
      return true;
    }
  };

  const mobileNumberValidateHandler = (num) => {
    setCheckoutFields((prevState) => ({ ...prevState, mobileNumber: num }));
    if ((num !== "" && !/([9876]{1})(\d{9})$/.test(num)) || num === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        mobileNumberError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        mobileNumberError: false,
      }));
      return true;
    }
  };

  const emailValidateHandler = (Email) => {
    setCheckoutFields((prevState) => ({ ...prevState, email: Email }));
    if (
      Email === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)
    ) {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        emailError: false,
      }));
      return true;
    }
  };

  const courseValidateHandler = (Course) => {
    setCheckoutFields((prevState) => ({ ...prevState, course: Course }));
    if (Course.trim() === "") {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        courseError: true,
      }));
      return false;
    } else {
      setCheckoutFieldsError((prevState) => ({
        ...prevState,
        courseError: false,
      }));
      return true;
    }
  };

  const amountHandler = (Amount) => {
    setCheckoutFields((prevState) => ({ ...prevState, amount: Amount }));
  };

  return (
    <div className="adjust-top">
      <div id="pricing-box" className="section wb1">
        <div className="container">
          <div class="card card-4">
            <div class="card-body">
              <h2 class="title">Enroll to Course</h2>
              <form
                action=""
                onSubmit={(event) => checkoutValidateHandler(event)}
              >
                <div class="form-group">
                  <label for="fullName">Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="fullName"
                    aria-describedby="emailHelp"
                    placeholder="John Doe"
                    className={
                      checkoutFieldsError.fullNameError &&
                      checkoutFields.fullName !== ""
                        ? "inputFieldError form-control"
                        : !checkoutFieldsError.fullNameError &&
                          checkoutFields.fullName !== ""
                        ? "inputFieldSuccess form-control"
                        : "form-control"
                    }
                    value={checkoutFields.fullName}
                    onChange={(event) =>
                      fullNameValidateHandler(event.target.value)
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="Email">Email address</label>
                  <input
                    type="email"
                    class="form-control"
                    id="Email"
                    aria-describedby="emailHelp"
                    placeholder="john@youremail.com"
                    className={
                      checkoutFieldsError.emailError &&
                      checkoutFields.email !== ""
                        ? "inputFieldError form-control"
                        : !checkoutFieldsError.emailError &&
                          checkoutFields.email !== ""
                        ? "inputFieldSuccess form-control"
                        : "form-control"
                    }
                    value={checkoutFields.email}
                    onChange={(event) =>
                      emailValidateHandler(event.target.value)
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="mobileNumber">Phone Number</label>
                  <input
                    type="mobile"
                    class="form-control"
                    id="mobileNumber"
                    placeholder="9999999999"
                    className={
                      checkoutFieldsError.mobileNumberError &&
                      checkoutFields.mobileNumber !== ""
                        ? "inputFieldError form-control"
                        : !checkoutFieldsError.mobileNumberError &&
                          checkoutFields.mobileNumber !== ""
                        ? "inputFieldSuccess form-control"
                        : "form-control"
                    }
                    value={checkoutFields.mobileNumber}
                    onChange={(event) =>
                      mobileNumberValidateHandler(event.target.value)
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="course">Course</label>
                  <input
                    type="text"
                    class="form-control"
                    id="course"
                    placeholder="Course to be Enrolled"
                    className={
                      checkoutFieldsError.courseError &&
                      checkoutFields.course !== ""
                        ? "inputFieldError form-control"
                        : !checkoutFieldsError.courseError &&
                          checkoutFields.course !== ""
                        ? "inputFieldSuccess form-control"
                        : "form-control"
                    }
                    value={checkoutFields.course}
                    onChange={(event) =>
                      courseValidateHandler(event.target.value)
                    }
                  />
                </div>
                <div class="form-group">
                  <label for="Amount">Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    id="Amount"
                    placeholder="1000"
                    value={checkoutFields.amount}
                    onChange={(event) => amountHandler(event.target.value)}
                  />
                </div>

                <div className="submit-wrapper">
                  <input
                    type="submit"
                    value={isProcessingRequest ? "Processing..." : "Pay now"}
                    className="btn btn-primary login-btn"
                    disabled={isProcessingRequest}
                    id="loginBtn"
                  />
                  <br />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomAlert
        show={sweetAlert.show}
        title={sweetAlert.title}
        body={sweetAlert.body}
        close={() =>
          setSweetAlert((prevState) => ({ ...prevState, show: false }))
        }
      />
    </div>
  );

  //   {
  //      <div class="page-wrapper bg-gra-02 p-t-130 p-b-100 font-poppins"></div>
  //       <div class="wrapper wrapper--w680">
  //         <div class="card card-4">
  //           <div class="card-body">
  //             <h2 class="title">Your Details</h2>
  //             <form method="POST">
  //               <div class="row row-space">
  //                 <div class="">
  //                   <div class="input-group">
  //                     <label class="label">first name</label>
  //                     <input
  //                       class="input--style-4"
  //                       type="text"
  //                       name="first_name"
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //   }
  {
    /* <div class="row row-space">
                <div class="col-2">
                  <div class="input-group">
                    <label class="label">Birthday</label>
                    <div class="input-group-icon">
                      <input
                        class="input--style-4 js-datepicker"
                        type="text"
                        name="birthday"
                      />
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="input-group">
                    <label class="label">Gender</label>
                    <div class="p-t-10">
                      <label class="radio-container m-r-45">
                        Male
                        <input type="radio" checked="checked" name="gender" />
                        <span class="checkmark"></span>
                      </label>
                      <label class="radio-container">
                        Female
                        <input type="radio" name="gender" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */
  }
  {
    /* <div class="row row-space">
                <div class="col-2">
                  <div class="input-group">
                    <label class="label">Email</label>
                    <input class="input--style-4" type="email" name="email" />
                  </div>
                </div>
                <div class="col-2">
                  <div class="input-group">
                    <label class="label">Phone Number</label>
                    <input class="input--style-4" type="text" name="phone" />
                  </div>
                </div>
              </div>
              <div class="input-group">
                <label class="label">Subject</label>
                <div class="rs-select2 js-select-simple select--no-search">
                  <select name="subject">
                    <option disabled="disabled" selected="selected">
                      Choose option
                    </option>
                    <option>Subject 1</option>
                    <option>Subject 2</option>
                    <option>Subject 3</option>
                  </select>
                  <div class="select-dropdown"></div>
                </div>
              </div>
              <div class="p-t-15">
                <button class="btn btn--radius-2 btn--blue" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */
  }
  {
    /* </div>
  ); */
  }
};

export default PayCheckout;
