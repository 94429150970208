import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Api from '../../api';
import "./Signup.css";
import CustomAlert from '../sweet-alert/sweetAlert';

const SignUp = () => {

    const [registerFields, setRegisterFields] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        confirmPassword: "",
        mobileNuber: "",
        aggreeTC: false
    });

    const [registerFieldsError, setRegisterFieldsError] = useState({
        firstNameError: false,
        lastNameError: false,
        userNameError: false,
        passwordError: false,
        confirmPasswordError: false,
        mobileNumberError: false
    });

    const [passwordVallidators, setPasswordVallidators] = useState({
        capitalLetter: false,
        smallLetter: false,
        number: false,
        specialCharacter: false
    });

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        body: "",
      });

    const registerValidateHandler = (event) => {
        event.preventDefault();
        // console.log(fullName + " " + rUserName + " " + rPassword + " " + mNumber);
        if (firstNameValidateHandler(registerFields.firstName) && lastNameValidateHandler(registerFields.lastName) && validateUserNameHandler(registerFields.userName, "register") && validatePasswordHandler(registerFields.password, "register") && confirmPasswordValidateHandler(registerFields.confirmPassword) && mobileNumberValidateHandler(registerFields.mobileNuber) && registerFields.aggreeTC) {
            const registerObject = {
                "first_name": registerFields.firstName,
                "last_name": registerFields.lastName,
                "mobileNo": registerFields.mobileNuber,
                "emailId": registerFields.userName,
                "password": registerFields.password,
                "role": "user",
                "agreeTC": registerFields.aggreeTC ? 1 : 0
            };
            console.log("register object is ", registerObject);
            Api.post("v1/users/user", registerObject).then(res => {
                console.log("User created successfully, proceed to login.", res)
                setSweetAlert({show:true, title: "Success", body: "User Created successfully, proceed to login." });
            }).catch(err => {
                setSweetAlert({show:true, title: "Failed", body: "Failed to create a new user, please try again." });
            });
        } else {
            alert("All the fields are mandatory, plese check again and proceed!");
        }
        return false;

    }

    const validateUserNameHandler = (data) => {
        setRegisterFields(prevState => ({ ...prevState, userName: data }));
        if (data === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(data)) {
            setRegisterFieldsError(prevState => ({ ...prevState, userNameError: true }));
            return false;
        }
        else {
            setRegisterFieldsError(prevState => ({ ...prevState, userNameError: false }));
            return true;
        }

    }

    const validatePasswordHandler = (data) => {
        ((/^(?=.*[a-z])/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, smallLetter: true })) : setPasswordVallidators(prevState => ({ ...prevState, smallLetter: false }));
        ((/^(?=.*[A-Z])/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, capitalLetter: true })) : setPasswordVallidators(prevState => ({ ...prevState, capitalLetter: false }));
        ((/^(?=.*[0-9])/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, number: true })) : setPasswordVallidators(prevState => ({ ...prevState, number: false }));
        ((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(registerFields.password))) ? setPasswordVallidators(prevState => ({ ...prevState, specialCharacter: true })) : setPasswordVallidators(prevState => ({ ...prevState, specialCharacter: false }));

        setRegisterFields(prevState => ({ ...prevState, password: data }));
        if (data === "" || !(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/).test(data)) {
           setRegisterFieldsError(prevState => ({ ...prevState, passwordError: true }));
            return false;
        } else {
            setRegisterFieldsError(prevState => ({ ...prevState, passwordError: false }));
            return true;
        }
    }

    const firstNameValidateHandler = (fName) => {
        setRegisterFields(prevState => ({ ...prevState, firstName: fName }));
        if (fName.trim() === "") {
            setRegisterFieldsError(prevState => ({ ...prevState, firstNameError: true }));
            return false;
        }
        else {
            setRegisterFieldsError(prevState => ({ ...prevState, firstNameError: false }));
            return true;
        }
    }

    const lastNameValidateHandler = (fName) => {
        setRegisterFields(prevState => ({ ...prevState, lastName: fName }));
        if (fName.trim() === "") {
            setRegisterFieldsError(prevState => ({ ...prevState, lastNameError: true }));
            return false;
        }
        else {
            setRegisterFieldsError(prevState => ({ ...prevState, lastNameError: false }));
            return true;
        }
    }

    const confirmPasswordValidateHandler = (confirmpwd) => {
        setRegisterFields(prevState => ({ ...prevState, confirmPassword: confirmpwd }));
        if (confirmpwd !== registerFields.password) {
            setRegisterFieldsError(prevState => ({ ...prevState, confirmPasswordError: true }));
            return false;
        } else {
            setRegisterFieldsError(prevState => ({ ...prevState, confirmPasswordError: false }));
            return true;
        }
    }

    const mobileNumberValidateHandler = (num) => {
        setRegisterFields(prevState => ({ ...prevState, mobileNuber: num }));
        if ((num !== "" && !(/([9876]{1})(\d{9})$/).test(num)) || (num === "")) {
            setRegisterFieldsError(prevState => ({ ...prevState, mobileNumberError: true }));
            return false;
        } else {
            setRegisterFieldsError(prevState => ({ ...prevState, mobileNumberError: false }));
            return true;
        }
    }


    return <div className="adjust-top" style={{marginBottom: "6em"}}>
        <div className="register-wrapper my-3" id="register">
            <div className="register-div">
                <div>
                    <h3 className="my-3">Register here.</h3>
                </div>
                <form action="" onSubmit={(event) => registerValidateHandler(event)}>
                    <div className="firstname-div input-field-wrapper">
                        <input type="text" id="firstName" className={(registerFieldsError.firstNameError && registerFields.firstName !== "") ? "inputFieldError form-control" : (!registerFieldsError.firstNameError && registerFields.firstName !== "") ? "inputFieldSuccess form-control" : "form-control"} value={registerFields.firstName} onChange={(event) => firstNameValidateHandler(event.target.value)} />
                        <span className="lable move-top">First Name</span>
                    </div>
                    <div className="lastname-div input-field-wrapper">
                        <input type="text" id="lastName" className={(registerFieldsError.lastNameError && registerFields.lastName !== "") ? "inputFieldError form-control" : (!registerFieldsError.lastNameError && registerFields.lastName !== "") ? "inputFieldSuccess form-control" : "form-control"} value={registerFields.lastName} onChange={(event) => lastNameValidateHandler(event.target.value)} />
                        <span className="lable move-top">Last Name</span>
                    </div>
                    <div className="username-div input-field-wrapper">
                        <input type="email" id="uName" className={(registerFieldsError.userNameError && registerFields.userName !== "") ? "inputFieldError form-control" : (!registerFieldsError.userNameError && registerFields.userName !== "") ? "inputFieldSuccess form-control" : "form-control"} value={registerFields.userName} onChange={(event) => validateUserNameHandler(event.target.value)} />
                        <span className="lable move-top">Email</span>
                    </div>
                    <div className="password-div input-field-wrapper">
                        <input type="password" id="password" value={registerFields.password} className={(registerFieldsError.passwordError && registerFields.password !== "") ? "inputFieldError form-control" : (!registerFieldsError.passwordError && registerFields.password !== "") ? "inputFieldSuccess form-control" : "form-control"} onChange={(event) => validatePasswordHandler(event.target.value)} />
                        <span className="lable move-top">Password</span>
                        <p className={(registerFields.password !== "" && registerFieldsError.passwordError) ? "" : "hide"} id="patternDiv">
                            <span id="smallCharEntity" className={passwordVallidators.smallLetter ? "entities entitiesVallid" : "entities"}>a</span>
                            <span id="capitalCharEntity" className={passwordVallidators.capitalLetter ? "entities entitiesVallid" : "entities"}>A</span>
                            <span id="numberEntity" className={passwordVallidators.number ? "entities entitiesVallid" : "entities"}>1</span>
                            <span id="symbolEntity" className={passwordVallidators.specialCharacter ? "entities entitiesVallid" : "entities"}>$</span>
                        </p>
                    </div>
                    <div className="cpassword-div input-field-wrapper">
                        <input type="password" id="cpassword" value={registerFields.confirmPassword} className={(registerFieldsError.confirmPasswordError && registerFields.confirmPassword !== "") ? "inputFieldError form-control" : (!registerFieldsError.confirmPasswordError && registerFields.confirmPassword !== "") ? "inputFieldSuccess form-control" : "form-control"} onChange={(event) => confirmPasswordValidateHandler(event.target.value, "register")} />
                        <span className="lable move-top">Confirm Password</span>
                    </div>
                    <div className="mobile-div input-field-wrapper">
                        <input type="mobile" id="mobileNumber" value={registerFields.mobileNuber} className={(registerFieldsError.mobileNumberError && registerFields.mobileNuber !== "") ? "inputFieldError form-control" : (!registerFieldsError.mobileNumberError && registerFields.mobileNuber !== "") ? "inputFieldSuccess form-control" : "form-control"} onChange={(event) => mobileNumberValidateHandler(event.target.value, "register")} />
                        <span className="lable move-top">Mobile</span>
                    </div>
                    <div className="submit-wrapper">
                        <label><input type="checkbox" id="aggree" checked={registerFields.aggreeTC} onChange={() => setRegisterFields(prevState => ({ ...prevState, aggreeTC: !registerFields.aggreeTC }))} /> &nbsp; <span>Agree Terms and Conditions</span></label>
                        <input type="submit" value="Register" className="btn btn-primary login-btn" disalbed="true" id="loginBtn" /><br />
                    </div>
                </form>
            </div>
        </div>        
      <CustomAlert
        show={sweetAlert.show}
        title={sweetAlert.title}
        body={sweetAlert.body}
        close={() =>
          setSweetAlert((prevState) => ({ ...prevState, show: false }))
        }
      />
    </div>
}

export default SignUp;