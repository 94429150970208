import React from "react";
import HomeComponent from "./components/Home/Home";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AboutWrapperComponent from "./components/About/About";
import HeaderComponent from "./components/Header/Header";
import FooterComponent from "./components/Footer/Footer";
import CoursesGridOne from "./components/CoursesGrid/CoursesGridOne";
import BlogComponent from "./components/Blog/Blog";
import BlogDetailComponent from "./components/Blog-Details/BlogDetail";
import TeachersComponent from "./components/Teachers/Teachers";
import ContactComponent from "./components/contact/Contact";
import PricingComponent from "./components/Pricing/Pricing";
import Login from "./components/login/login";
import CourseDetail from "./components/course-details/CourseDetail";
import { combineReducers, createStore } from "redux";
import reducer from "./store/reducer";
import { Provider } from "react-redux";
import Auth from "./components/login/Auth";
import TeacherDetails from "./components/Teacher-details/TeacherDetails";
import RegisterOnlineComponent from "./components/Register-online/RegisterOnline";
import PayCheckout from "./components/Payment-Checkout/checkout";
import SignUp from "./components/Signup/Signup";
import Talent from "./components/Talent-Screening/talent";
// import BlogDetail from "./components/Blog-Details/BlogDetail";

class App extends React.Component {
  state = {
    showLoginPopup: false,
    referrer: "login",
  };

  //if you have multiple reducer uncommnet this below and pass all the reducers as props to the below method.
  //initialReducer = combineReducers(reducer);

  store = createStore(reducer);

  render() {
    return (
      <div id="app">
        <Provider store={this.store}>
          <Router>
            <HeaderComponent
              showLogin={(ref) =>
                this.setState({ showLoginPopup: true, referrer: ref })
              }
            />
            <Route path="/" exact component={HomeComponent} />
            <Route path="/about" exact component={AboutWrapperComponent} />
            <Route path="/course" exact component={CoursesGridOne} />
            <Route path="/course/:id/:name" exact component={CourseDetail} />
            <Route path="/blog" exact component={BlogComponent} />
            <Route
              path="/blog/:id/:name"
              exact
              component={BlogDetailComponent}
            />
            <Route path="/faculty" exact component={TeachersComponent} />
            <Route path="/faculty/:id/:name" exact component={TeacherDetails} />
            <Route path="/pricing" exact component={PricingComponent} />
            <Route path="/contact" exact component={ContactComponent} />
            <Route
              path="/register-onine"
              exact
              component={RegisterOnlineComponent}
            />
            <Route path="/login" exact component={Auth} />
            <Route path="/paycheckout" exact component={PayCheckout} />
            <Route path="/test" exact component={Talent} />
            <Route path="/signup" exact component={SignUp} />
            <FooterComponent />
            <Login
              visible={this.state.showLoginPopup}
              referrer={this.state.referrer}
              closePopup={() => this.setState({ showLoginPopup: false })}
            />
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
