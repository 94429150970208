import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Api from "../../api";
import InnerLoder from "../Loader-inner/InnerLoader";
import PageNation from "../Page-Nation/PageNation";
import { Helmet } from "react-helmet";

const BlogComponent = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [blogsBackUp, setBlogsBackUp] = useState([{}]);
  const [blogs, setBlogs] = useState([{}]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);
  const [pageNationArray, setPageNationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    //document.title = "Our Blog - Gr Study Circle";
    Api.get("v1/blogs/")
      .then((res) => {
        console.log("res: all courses ", res);
        setBlogsBackUp(res.data.result);
        preFetchImages(res.data.result);
        loadBlogsToShow(res.data.result, currentPage);
        let pageCountArray = [];
        for (let i = 0, j = 1; i < res.data.result.length / 12; i++) {
          pageCountArray.push(j);
          ++j;
        }
        setPageNationArray(pageCountArray);
        setShowLoader(false);
      })
      .catch((err) => console.log("Error while retriving courses from data"));
    window.scrollTo(0, 0);
  }, []);

  const loadBlogsToShow = (dataArray, page) => {
    let dataToShow = [];
    for (let i = (page - 1) * 12; i < page * 12; i++) {
      if (dataArray[i]) dataToShow.push(dataArray[i]);
    }
    console.log("Data getting rendered: ", dataToShow);
    setBlogs(dataToShow);
  };

  const preFetchImages = (blogsList) => {
    let imagesArray = blogsList.map((blog) => blog.image);
    caheImage(removeDuplicatesInArray(imagesArray), 0);
  };

  const caheImage = (imagesArray, ind) => {
    if (ind < imagesArray.length) {
      imgPromise(imagesArray[ind])
        .then(() => {
          caheImage(imagesArray, ++ind);
        })
        .catch((err) => {
          caheImage(imagesArray, ind);
        });
    } else {
      console.log("All the images are loaded, stopping the spinner");
      setTimeout(() => {
        setIsAllImagesLoaded(true);
      }, 3000);
    }
  };

  const imgPromise = (src) => {
    console.log(src);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = "https://cdn.griasacademy.com" + src;
      img.onload = resolve();
      img.onerror = reject();
    });
  };

  const removeDuplicatesInArray = (imgArray) => {
    let filteredImagesArray = imgArray.filter(
      (img, index, imgArray) => imgArray.indexOf(img) === index
    );
    return filteredImagesArray;
  };

  const filterBlogs = (event) => {
    let filteredBlogs = [];
    if (event.target.value === "") {
      setBlogs(blogsBackUp);
    } else {
      blogsBackUp.map((course) => {
        if (course.category === event.target.value) filteredBlogs.push(course);
      });
      setBlogs(filteredBlogs);
    }
    setIsFiltered(true);
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className="adjust-top">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Blog - Gr IAS Academy</title>
        <meta
          name="keywords"
          content="online coaching for ias,top 10 ias coaching institutions in india,best online coaching for ias,upsc civil service exam coaching,civil service preliminary exam syllabus,upsc civil service optional subjects,upsc civil service interview,upsc civil service interview coaching,upsc civil service mains exam pattern"
        />
        <meta
          name="og:keywords"
          content="online coaching for ias,top 10 ias coaching institutions in india,best online coaching for ias,upsc civil service exam coaching,civil service preliminary exam syllabus,upsc civil service optional subjects,upsc civil service interview,upsc civil service interview coaching,upsc civil service mains exam pattern"
        />
      </Helmet>
      {!isAllImagesLoaded ? (
        <InnerLoder />
      ) : (
        <div id="overviews" className="section wb">
          <div className="container">
            <hr className="invis" />

            <PageNation>
              <div className="row">
                {blogs.map((blog, index) => {
                  let name = blog.title;
                  if (name) {
                    name = blog.title.replace(/,?\s+/g, "-");
                    console.log("nameeeee", name);
                  }

                  let date = new Date(blog.cd);
                  console.log("cd", blog.cd);
                  let month = "" + (date.getMonth() + 1);
                  let day = "" + date.getDate();
                  let year = date.getFullYear();

                  if (month.length < 2) month = "0" + month;
                  if (day.length < 2) day = "0" + day;
                  date = [year, month, day].join("-");

                  let description =
                    blog.shortDescription.split(" ").splice(0, 20).join(" ") +
                    "...";

                  return (
                    <div className="col-lg-4 col-md-6 col-12 my-4">
                      <div
                        className="blog-item"
                        style={{
                          minHeight: "506px",
                          maxHeight: "506px",
                        }}
                      >
                        <Link to={"/blog/" + blog.id + "/" + name}>
                          <div className="image-blog">
                            <Link to={"/blog/" + blog.id + "/" + name} title="">
                              <img
                                src={
                                  "https://cdn.griasacademy.com" + blog.image
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                        </Link>
                        <div className="meta-info-blog">
                          <span>
                            <i className="fa fa-calendar"></i>{" "}
                            <p
                              style={{
                                display: "inline",
                                color: "#eea412",
                              }}
                            >
                              {date}
                            </p>
                          </span>
                          <span>
                            <i className="fa fa-tag"></i>{" "}
                            <p
                              style={{
                                display: "inline",
                                color: "#eea412",
                              }}
                            >
                              {blog.category}
                            </p>
                          </span>
                        </div>
                        <div className="blog-title">
                          <h2>
                            <Link
                              to={"/blog/" + blog.id + "/" + name}
                              title=""
                              style={{ color: "#0c2e60" }}
                            >
                              {blog.title}
                            </Link>
                          </h2>
                        </div>
                        <div
                          className="blog-desc"
                          style={{ marginBottom: "10px" }}
                        >
                          <p>{description}</p>
                        </div>
                        <div className="blog-button">
                          <Link
                            to={"/blog/" + blog.id + "/" + name}
                            className="hover-btn-new orange"
                          >
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </PageNation>
            {!isFiltered ? (
              <nav
                aria-label="Page navigation example"
                className="my-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ul className="pagination">
                  <li class="page-item">
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => {
                        setCurrentPage(
                          currentPage - 1 <= 1 ? 1 : currentPage - 1
                        );
                        loadBlogsToShow(
                          blogsBackUp,
                          currentPage - 1 <= 1 ? 1 : currentPage - 1
                        );
                      }}
                    >
                      Previous
                    </Link>
                  </li>
                  {pageNationArray.map((page) => (
                    <li className="page-item">
                      <Link
                        to="#"
                        className={
                          currentPage === page
                            ? "page-link active"
                            : "page-link"
                        }
                        onClick={() => {
                          setCurrentPage(page);
                          loadBlogsToShow(blogsBackUp, page);
                        }}
                      >
                        {page}
                      </Link>
                    </li>
                  ))}
                  <li class="page-item">
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => {
                        setCurrentPage(
                          currentPage + 1 >= pageNationArray.length
                            ? pageNationArray.length
                            : currentPage + 1
                        );
                        loadBlogsToShow(
                          blogsBackUp,
                          currentPage + 1 >= pageNationArray.length
                            ? pageNationArray.length
                            : currentPage + 1
                        );
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogComponent;
