import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Popup from '../Popup/Popup';

import Api from '../../api';
import './login.css';
import SnackBar from '../Snackbar/Snackbar';



const Login = (prop) => {
    useEffect(() => {
        openPopup(prop);
    });

    const [visiblePopup, setVisiblePopup] = useState(false);
    const [visibleRefferer, setVisibleRefferer] = useState("login");
    const [snackBarProps, setSnackBarProps] = useState({
        visible: false,
        message: ""
    });


    const [showSweetAlert, setShowSweetAlert] = useState({
        show: false,
        title: "",
        body: ""
    });
    const [loginFields, setLoginFields] = useState({
        userName: "",
        password: ""
    });

    const [loginFieldsError, setLoginFieldsError] = useState({
        userNameError: false,
        passwordError: false
    });



    const loginValidateHandler = (event) => {
        event.preventDefault();
        if (validateUserNameHandler(loginFields.userName) && validatePasswordHandler(loginFields.password)) {
            const loginObject = {
                "emailId": loginFields.userName,
                "password": loginFields.password
            }

            Api.post("v1/users/authenticate", loginObject).then(res => {
                setSnackBarProps({
                    visible: true,
                    message: "Login Success!"
                })
                console.log("got data from json placeholder: ", res);
            }).catch(err => {
                alert("Login failed please try again.");
            });
        } else {
            alert("All the fields are mandatory, please check before you signin.");
        }
        return false;
    }

    const validateUserNameHandler = (data) => {
        setLoginFields(prevState => ({ ...prevState, userName: data }));
        if (data === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(data)) {
           setLoginFieldsError(prevState => ({
                ...prevState, userNameError: true
            }));
            return false;
        }
        else {
            setLoginFieldsError(prevState => ({
                ...prevState, userNameError: false
            }));
            return true;
        }

    } 

    const validatePasswordHandler = (data) => {
        
       setLoginFields(prevState => ({ ...prevState, password: data }));
        if (data === "" || !(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/).test(data)) {
            setLoginFieldsError(prevState => ({ ...prevState, passwordError: true }));
            return false;
        } else {
           setLoginFieldsError(prevState => ({ ...prevState, passwordError: false }));
            return true;
        }
    }
    
    const switchpage = (event) => {
        event.preventDefault();
        document.getElementById("login").classList.toggle("hide");
        document.getElementById("register").classList.toggle("hide");
    }

    const openPopup = (prop) => {
        setVisiblePopup(prop.visible);
        setVisibleRefferer(prop.referrer);
    }

    return (
        <div id="popup-div" onClick={prop.closePopup}>
            <Popup visible={visiblePopup} height="100%" width="100%" effect="fadeInDown" >
                <div className="wrapper py-3" onClick={(event) => event.stopPropagation()}>
                    <div className="welcome-wrapper">
                        <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    </div>
                    <div className={visibleRefferer === "login" ? "login-wrapper" : "login-wrapper hide"} id="login">
                        <div className="login-div">
                            <div className="close-button" onClick={prop.closePopup}><span>&times;</span></div>
                            <form action="" onSubmit={(event) => loginValidateHandler(event)}>
                                <div className="username-div input-field-wrapper">
                                    <input type="email" id="userName" className={(loginFieldsError.userNameError && loginFields.userName !== "") ? "inputFieldError form-control" : (!loginFieldsError.userNameError && loginFields.userName !== "") ? "inputFieldSuccess form-control" : "form-control"} value={loginFields.userName} onChange={(event) => validateUserNameHandler(event.target.value)} />
                                    <span className="lable move-top">Email</span>
                                </div>
                                <div className="password-div input-field-wrapper">
                                    <input type="password" id="pwd" value={loginFields.password} className={(loginFieldsError.passwordError && loginFields.password !== "") ? "inputFieldError form-control" : (!loginFieldsError.passwordError && loginFields.password !== "") ? "inputFieldSuccess form-control" : "form-control"} onChange={(event) => validatePasswordHandler(event.target.value)} />
                                    <span className="lable move-top">Password</span>
                                </div>
                                <div className="submit-wrapper">
                                    <label><input type="checkbox" id="remember" /> &nbsp; <span>Remember me</span></label>
                                    <input type="submit" value="Login" className="btn btn-primary login-btn" disalbed="true" id="loginBtn" /><br />
                                </div>
                                <div className="become-member"><Link to="/signup" onClick={prop.closePopup}>Become a member.</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
            </Popup>
            <SnackBar message={snackBarProps.message} visibleSnackBar={snackBarProps.visible} />
        </div>
    );
}

export default Login;