import React, { useState } from "react";
import { Link } from "react-router-dom";
import RequestDemo from "../RequestDemo/RequestDemo";

const HomeCarousel = () => {
  const [showPopUp, setShowPopUp] = useState(false);

  return (
    <div>
      <div
        id="carouselExampleControls"
        className="carousel slide bs-slider box-slider"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleControls"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleControls" data-slide-to="1"></li>
          <li data-target="#carouselExampleControls" data-slide-to="2"></li>
          <li data-target="#carouselExampleControls" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <div
              id="home"
              className="first-section"
              style={{ backgroundImage: "url('images/slider-02.jpg')" }}
            >
              <div className="dtab">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 text-left">
                      <div className="big-tagline">
                        <h2 data-animation="animated zoomInRight">
                          <strong> Offline/Online Batches </strong>
                          Starting in Hyderabad
                        </h2>
                        {/* <p
                          className="lead"
                          data-animation="animated fadeInLeft"
                        >
                          With Landigoo responsive landing page template, you
                          can promote your all hosting, domain and email
                          services.{" "}
                        </p> */}
                        <Link to="/course" className="hover-btn-new">
                          <span>View Now</span>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="/"
                          onClick={() => setShowPopUp(true)}
                          className="hover-btn-new"
                        >
                          <span>Request Demo</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div
              id="home"
              className="first-section"
              style={{
                backgroundImage: "url('images/slider0007.png')",
                backgroundSize: "100% 100%",
              }}
            >
              <div className="mt-5"></div>
              <div className="dtab mt-5">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-12 col-sm-12 text-right mt-5">
                      <div className="big-tagline mt-5">
                        {/* <h2>IAS 2024</h2> */}
                        {/* <p className="lead">
                          With Landigoo responsive landing page template, you
                          can promote your all hosting, domain and email
                          services.{" "}
                        </p> */}
                        <Link
                          to="/register-onine"
                          className="hover-btn-new mt-5"
                        >
                          <span>Apply Now</span>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="/"
                          onClick={() => setShowPopUp(true)}
                          className="hover-btn-new mt-5"
                        >
                          <span>Request Demo</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div
              id="home"
              className="first-section"
              style={{
                backgroundImage: "url('images/slider-01.jpg')",
              }}
            >
              <div className="dtab">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 text-right">
                      <div className="big-tagline">
                        <h2>
                          Gear up for
                          <strong> Mission 2025</strong>
                        </h2>
                        {/* <p className="lead">
                          With Landigoo responsive landing page template, you
                          can promote your all hosting, domain and email
                          services.{" "}
                        </p> */}
                        <Link to="/register-onine" className="hover-btn-new">
                          <span>Apply Now</span>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="/"
                          onClick={() => setShowPopUp(true)}
                          className="hover-btn-new"
                        >
                          <span>Request Demo</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div
              id="home"
              className="first-section"
              style={{ backgroundImage: "url('images/slider-03.jpg')" }}
            >
              <div className="dtab">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 text-center">
                      <div className="big-tagline">
                        <h2
                          data-animation="animated zoomInRight"
                          style={{ fontSize: "25px" }}
                        >
                          UPSC Civils Services Exam Preparation Course New Batch
                          Started-Enroll Now
                        </h2>
                        {/* <p
                          className="lead"
                          data-animation="animated fadeInLeft"
                        >
                          1 IP included with each server Your Choice of any OS
                          (CentOS, Windows, Debian, Fedora) FREE Reboots
                        </p> */}
                        <Link to="/course" className="hover-btn-new">
                          <span>view Now</span>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="/"
                          onClick={() => setShowPopUp(true)}
                          className="hover-btn-new"
                        >
                          <span>Request Demo</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a
            className="new-effect carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span className="fa fa-angle-left" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="new-effect carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span className="fa fa-angle-right" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <RequestDemo
          visible={showPopUp}
          closePopup={() => setShowPopUp(false)}
        />
      </div>
    </div>
  );
};

export default HomeCarousel;
